import React, { useState, useEffect } from 'react';
import { 
  Paper, 
  Typography, 
  Box, 
  TextField, 
  Button, 
  LinearProgress, 
  Grid,
  Divider,
  Alert,
  InputAdornment
} from '@mui/material';
import { useExpenses } from '../../contexts/ExpenseContext';
import { useTranslation } from 'react-i18next';

const BudgetManager: React.FC = () => {
  const { t } = useTranslation();
  const { 
    getMonthlyBalance,
    getBudgetStatus,
    getExpensePercentage,
    currency,
    getTotalMonthlyIncome,
    incomeSources,
    updateIncomeSource,
    getTotalMonthlyExpenses,
    getRegularMonthlyExpenses,
    getProratedAnnualExpenses
  } = useExpenses();
  
  // Get monthly income from all sources
  const monthlyIncome = getTotalMonthlyIncome();
  
  const [inputIncome, setInputIncome] = useState(monthlyIncome.toString());
  const [showSuccess, setShowSuccess] = useState(false);

  // Update input when currency changes
  useEffect(() => {
    setInputIncome(monthlyIncome.toString());
  }, [monthlyIncome]);

  // Get regular monthly expenses for all family members
  const regularMonthExpenses = getRegularMonthlyExpenses();
  
  // Get prorated annual expenses for all family members
  const monthlyEquivalentOfAnnual = getProratedAnnualExpenses();
  
  // Get total monthly expenses (regular + prorated annual)
  const totalMonthExpenses = getTotalMonthlyExpenses();
  
  // Calculate monthly balance
  const monthlyBalance = getMonthlyBalance();
  
  // Annual calculations are now done in the Dashboard component
  
  // Get budget status and percentage
  const budgetStatus = getBudgetStatus();
  const expensePercentage = getExpensePercentage();

  // Progress bar color based on budget status
  const getProgressColor = (status: 'good' | 'warning' | 'danger') => {
    switch (status) {
      case 'good': return 'success';
      case 'warning': return 'warning';
      case 'danger': return 'error';
      default: return 'primary';
    }
  };

  // Budget status text
  const getBudgetStatusText = (status: 'good' | 'warning' | 'danger') => {
    switch (status) {
      case 'good': return `✅ ${t('dashboard.onBudget')}`;
      case 'warning': return `⚠️ ${t('dashboard.budgetCaution')}`;
      case 'danger': return `🔴 ${t('dashboard.overBudget')}`;
      default: return '';
    }
  };

  const handleIncomeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputIncome(e.target.value);
  };

  const handleSaveIncome = () => {
    const income = parseFloat(inputIncome);
    if (!isNaN(income) && income >= 0) {
      // Find primary salary or create one if it doesn't exist
      const primarySalary = incomeSources.find(source => source.name === t('income.primarySalary'));
      
      if (primarySalary) {
        // Update existing primary salary
        updateIncomeSource(primarySalary.id, { amount: income });
      } else if (incomeSources.length > 0) {
        // Update the first income source
        updateIncomeSource(incomeSources[0].id, { amount: income });
      }
      
      setShowSuccess(true);
      setTimeout(() => setShowSuccess(false), 3000);
    }
  };

  return (
    <Paper sx={{ p: 3, mb: 3 }}>
      <Typography variant="h6" gutterBottom>
        {t('header.budget')}
      </Typography>
      
      <Divider sx={{ mb: 2 }} />
      
      <Grid container spacing={3}>
        {/* Budget Input Section */}
        <Grid item xs={12} md={6}>
          <Box sx={{ mb: 3 }}>
            <Typography variant="subtitle1" gutterBottom>
              {t('dashboard.totalMonthlyIncome')}
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
              <TextField
                label={t('dashboard.totalMonthlyIncome')}
                variant="outlined"
                type="number"
                value={inputIncome}
                onChange={handleIncomeChange}
                fullWidth
                inputProps={{ min: 0, step: 0.01 }}
                sx={{ mr: 1 }}
                InputProps={{
                  startAdornment: <InputAdornment position="start">{currency.symbol}</InputAdornment>,
                }}
              />
              <Button 
                variant="contained" 
                color="primary" 
                onClick={handleSaveIncome}
                sx={{ height: 56 }}
              >
                {t('common.save')}
              </Button>
            </Box>
            {showSuccess && (
              <Alert severity="success" sx={{ mt: 1 }}>
                {t('income.income')} {t('common.save')}!
              </Alert>
            )}
          </Box>
        </Grid>
        
        {/* Budget Summary Section */}
        <Grid item xs={12} md={6}>
          <Typography variant="subtitle1" gutterBottom>
            {t('dashboard.summary')}
          </Typography>
          <Box sx={{ mb: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="body2" color="text.secondary">
                  {t('dashboard.totalMonthlyIncome')}:
                </Typography>
                <Typography variant="h6">
                  {currency.symbol}{monthlyIncome.toFixed(2)} {currency.code}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" color="text.secondary">
                  {t('dashboard.monthlyExpenses')}:
                </Typography>
                <Typography variant="h6">
                  {currency.symbol}{totalMonthExpenses.toFixed(2)} {currency.code}
                </Typography>
                <Typography variant="caption" color="text.secondary">
                  {t('expenses.regular')}: {currency.symbol}{(regularMonthExpenses > 0 ? regularMonthExpenses : 0).toFixed(2)} • 
                  {t('expenses.annual')}: {currency.symbol}{(monthlyEquivalentOfAnnual > 0 ? monthlyEquivalentOfAnnual : 0).toFixed(2)}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" color="text.secondary">
                  {t('dashboard.monthlyBalance')}:
                </Typography>
                <Typography 
                  variant="h6" 
                  color={monthlyBalance >= 0 ? 'success.main' : 'error.main'}
                >
                  {currency.symbol}{monthlyBalance.toFixed(2)} {currency.code}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        
        {/* Budget Progress Section */}
        <Grid item xs={12}>
          <Box sx={{ mb: 1, mt: 2 }}>
            <Typography variant="subtitle1" gutterBottom>
              {t('dashboard.budgetStatus')}
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <Typography variant="body2" color="text.secondary" sx={{ mr: 1 }}>
                {expensePercentage.toFixed(1)}% {t('charts.ofTotal')}
              </Typography>
              <Typography variant="body2" ml="auto">
                {currency.symbol}{totalMonthExpenses.toFixed(2)} / {currency.symbol}{monthlyIncome.toFixed(2)}
              </Typography>
            </Box>
            <LinearProgress 
              variant="determinate" 
              value={Math.min(expensePercentage, 100)} 
              color={getProgressColor(budgetStatus) as 'success' | 'warning' | 'error' | 'primary' | 'secondary' | 'info'}
              sx={{ height: 10, borderRadius: 5 }}
            />
            <Typography variant="body2" sx={{ mt: 1 }} color={`${getProgressColor(budgetStatus)}.main`}>
              {getBudgetStatusText(budgetStatus)}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default BudgetManager;