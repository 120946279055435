import React from 'react';
import { Box, Typography, Container, Divider } from '@mui/material';
import { Outlet } from 'react-router-dom';
import Header from './Header';
import { useTranslation } from 'react-i18next';
import packageInfo from '../../../package.json';

const Footer: React.FC = () => {
  const { t } = useTranslation();
  const version = packageInfo.version;
  
  return (
    <Box 
      component="footer" 
      sx={{ 
        py: 3, 
        px: 2, 
        mt: 'auto',
        backgroundColor: (theme) => theme.palette.grey[100]
      }}
    >
      <Container maxWidth="lg">
        <Divider sx={{ mb: 2 }} />
        <Typography variant="body2" color="text.secondary" align="center">
          {t('dashboard.financeTracker')} • {t('dashboard.version')}: {version}
        </Typography>
      </Container>
    </Box>
  );
};

const Layout: React.FC = () => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Header />
      <Box 
        component="main" 
        sx={{ 
          flexGrow: 1, 
          bgcolor: 'background.default', 
          p: 3 
        }}
      >
        <Outlet />
      </Box>
      <Footer />
    </Box>
  );
};

export default Layout;