import React, { useState } from 'react';
import { 
  TextField, 
  Button, 
  Box, 
  Typography, 
  Container, 
  Paper,
  Alert,
  Link,
  Divider,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@mui/material';
import { Google as GoogleIcon } from '@mui/icons-material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { useTranslation } from 'react-i18next';

const Login: React.FC = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [googleLoading, setGoogleLoading] = useState(false);
  
  // Link account dialog
  const [openLinkDialog, setOpenLinkDialog] = useState(false);
  const [existingEmail, setExistingEmail] = useState('');
  
  const { login, loginWithGoogle, linkWithGoogle } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    // Reset error
    setError('');
    
    try {
      setLoading(true);
      await login(email, password);
      navigate('/'); // Redirect to dashboard after login
    } catch (err: any) {
      setError(err.message || 'Failed to login');
    } finally {
      setLoading(false);
    }
  };
  
  const handleGoogleLogin = async () => {
    setError('');
    setGoogleLoading(true);
    
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { user, isNewUser, linkedAccount } = await loginWithGoogle();
      
      // If we linked an account during login, show a message
      if (linkedAccount) {
        // In a real app, you might show a success message here
        console.log('Successfully linked your Google account!');
      }
      
      navigate('/');
    } catch (err: any) {
      // Check if error is due to account already exists
      if (err.code === 'auth/account-exists-with-different-credential') {
        setExistingEmail(err.customData?.email || '');
        setOpenLinkDialog(true);
      } else {
        setError(err.message || 'Failed to login with Google');
      }
    } finally {
      setGoogleLoading(false);
    }
  };
  
  const handleLinkAccounts = async () => {
    try {
      await linkWithGoogle();
      setOpenLinkDialog(false);
      navigate('/');
    } catch (err: any) {
      setError(err.message || 'Failed to link accounts');
      setOpenLinkDialog(false);
    }
  };

  return (
    <Container maxWidth="xs">
      <Paper elevation={3} sx={{ p: 4, mt: 8 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography component="h1" variant="h5">
            {t('auth.login')}
          </Typography>
          
          {error && (
            <Alert severity="error" sx={{ width: '100%', mt: 2 }}>
              {error}
            </Alert>
          )}
          
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3, width: '100%' }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label={t('auth.email')}
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label={t('auth.password')}
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={loading}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                t('auth.signIn')
              )}
            </Button>
            
            <Divider sx={{ mt: 2, mb: 2 }}>
              <Typography variant="body2" color="text.secondary">
                {t('auth.orContinueWith')}
              </Typography>
            </Divider>
            
            <Button
              fullWidth
              variant="outlined"
              startIcon={<GoogleIcon />}
              onClick={handleGoogleLogin}
              disabled={googleLoading}
              sx={{ mt: 1, mb: 2 }}
            >
              {googleLoading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                t('auth.googleSignIn')
              )}
            </Button>
            
            <Box sx={{ textAlign: 'center', mt: 2 }}>
              <Link component={RouterLink} to="/register" variant="body2">
                {t('auth.dontHaveAccount')} {t('auth.signUp')}
              </Link>
            </Box>
          </Box>
        </Box>
      </Paper>
      
      {/* Account linking dialog */}
      <Dialog open={openLinkDialog} onClose={() => setOpenLinkDialog(false)}>
        <DialogTitle>{t('auth.linkAccounts')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('auth.googleSignIn')} {existingEmail} {t('auth.alreadyHaveAccount')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenLinkDialog(false)} color="primary">
            {t('common.cancel')}
          </Button>
          <Button onClick={handleLinkAccounts} color="primary">
            {t('auth.linkAccounts')}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Login;