import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from './AuthContext';
import { languages } from '../i18n/i18n';
import { 
  userSettingsCollection,
  addDoc,
  updateDoc,
  getDocs,
  doc,
  query,
  where,
  Timestamp,
  db
} from '../firebase';

// Language interface
export interface Language {
  code: string;
  name: string;
}

interface LanguageContextType {
  language: Language;
  setLanguage: (language: Language) => void;
  availableLanguages: Language[];
}

// Create context
const LanguageContext = createContext<LanguageContextType | undefined>(undefined);

// Default language
const defaultLanguage: Language = languages[0]; // English

// Get language from localStorage
const getStoredLanguage = (): Language => {
  const storedLanguageCode = localStorage.getItem('financeTrackerLanguage');
  if (storedLanguageCode) {
    const foundLanguage = languages.find(lang => lang.code === storedLanguageCode);
    if (foundLanguage) {
      return foundLanguage;
    }
  }
  return defaultLanguage;
};

// Provider component
export const LanguageProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { currentUser } = useAuth();
  const { i18n } = useTranslation();
  const [language, setLanguageState] = useState<Language>(getStoredLanguage());

  // Initialize with the stored language
  useEffect(() => {
    i18n.changeLanguage(language.code);
  }, []);

  // Load language preference from Firestore when user logs in
  useEffect(() => {
    if (currentUser) {
      const userId = currentUser.uid;
      
      const fetchLanguagePreference = async () => {
        try {
          // Get user settings document
          const settingsQuery = query(userSettingsCollection, where("userId", "==", userId));
          const settingsSnapshot = await getDocs(settingsQuery);
          
          if (settingsSnapshot.docs.length > 0) {
            const settings = settingsSnapshot.docs[0].data();
            if (settings.language) {
              // Set language from user settings
              setLanguageState(settings.language);
              i18n.changeLanguage(settings.language.code);
              // Also update localStorage
              localStorage.setItem('financeTrackerLanguage', settings.language.code);
            } else {
              // If user has settings but no language preference,
              // save the current language to their settings
              await updateDoc(doc(db, 'userSettings', settingsSnapshot.docs[0].id), {
                language,
                updatedAt: Timestamp.now()
              });
            }
          } else {
            // Create new settings document with current language
            await addDoc(userSettingsCollection, {
              userId: currentUser.uid,
              language,
              createdAt: Timestamp.now()
            });
          }
        } catch (error) {
          console.error('Error loading language preference from Firestore:', error);
        }
      };
      
      fetchLanguagePreference();
    } else {
      // For non-logged-in users, use the localStorage value
      const storedLanguage = getStoredLanguage();
      setLanguageState(storedLanguage);
      i18n.changeLanguage(storedLanguage.code);
    }
  }, [currentUser, i18n]);

  // Update language and save to Firestore and localStorage
  const setLanguage = async (newLanguage: Language) => {
    // Always save to localStorage for persistence
    localStorage.setItem('financeTrackerLanguage', newLanguage.code);
    
    if (!currentUser) {
      setLanguageState(newLanguage);
      i18n.changeLanguage(newLanguage.code);
      return;
    }
    
    try {
      // Get the user settings document for this user
      const settingsQuery = query(userSettingsCollection, where("userId", "==", currentUser.uid));
      const settingsSnapshot = await getDocs(settingsQuery);
      
      if (settingsSnapshot.docs.length > 0) {
        // Update existing settings document
        const settingsDoc = settingsSnapshot.docs[0];
        await updateDoc(doc(db, 'userSettings', settingsDoc.id), {
          language: newLanguage,
          updatedAt: Timestamp.now()
        });
      } else {
        // Create new settings document
        await addDoc(userSettingsCollection, {
          userId: currentUser.uid,
          language: newLanguage,
          createdAt: Timestamp.now()
        });
      }
      
      // Update local state and i18n language
      setLanguageState(newLanguage);
      i18n.changeLanguage(newLanguage.code);
    } catch (error) {
      console.error('Error saving language preference to Firestore:', error);
    }
  };

  return (
    <LanguageContext.Provider value={{ 
      language,
      setLanguage,
      availableLanguages: languages
    }}>
      {children}
    </LanguageContext.Provider>
  );
};

// Custom hook to use the language context
export const useLanguage = () => {
  const context = useContext(LanguageContext);
  if (context === undefined) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
};