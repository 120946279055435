import React, { useState } from 'react';
import {
  Paper,
  Typography,
  Box,
  Button,
  TextField,
  IconButton,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Divider,
  InputAdornment,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Switch
} from '@mui/material';
import { Add, Edit, Delete } from '@mui/icons-material';
import { useExpenses, IncomeSource } from '../../contexts/ExpenseContext';

const IncomeManager: React.FC = () => {
  const {
    incomeSources,
    addIncomeSource,
    updateIncomeSource,
    deleteIncomeSource,
    getTotalMonthlyIncome,
    getTotalAnnualIncome,
    currency,
  } = useExpenses();

  const [open, setOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [currentIncomeId, setCurrentIncomeId] = useState('');
  const [name, setName] = useState('');
  const [amount, setAmount] = useState('');
  const [frequency, setFrequency] = useState<'monthly' | 'annual' | 'one-time'>('monthly');

  // Total calculations
  const totalMonthlyIncome = getTotalMonthlyIncome();
  const totalAnnualIncome = getTotalAnnualIncome();

  const handleClickOpen = (isEdit: boolean = false, income?: IncomeSource) => {
    setEditMode(isEdit);
    if (isEdit && income) {
      setCurrentIncomeId(income.id);
      setName(income.name);
      setAmount(income.amount.toString());
      setFrequency(income.frequency);
    } else {
      setCurrentIncomeId('');
      setName('');
      setAmount('');
      setFrequency('monthly');
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    const incomeData = {
      name,
      amount: parseFloat(amount),
      frequency,
    };

    if (editMode) {
      updateIncomeSource(currentIncomeId, incomeData);
    } else {
      addIncomeSource(incomeData);
    }

    handleClose();
  };

  const handleDelete = (id: string) => {
    deleteIncomeSource(id);
  };

  // Format frequency for display
  const formatFrequency = (freq: string) => {
    switch (freq) {
      case 'monthly':
        return 'Monthly';
      case 'annual':
        return 'Annual';
      case 'one-time':
        return 'One-time';
      default:
        return freq;
    }
  };

  return (
    <Paper sx={{ p: 3, mb: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h6">Income Sources</Typography>
        <Button
          variant="contained"
          startIcon={<Add />}
          onClick={() => handleClickOpen(false)}
        >
          Add Income Source
        </Button>
      </Box>

      <Divider sx={{ mb: 3 }} />

      {/* Income Summary */}
      <Grid container spacing={2} sx={{ mb: 3 }}>
        <Grid item xs={12} sm={6}>
          <Box sx={{ p: 2, border: '1px solid #e0e0e0', borderRadius: 1 }}>
            <Typography variant="subtitle2" color="text.secondary">
              Total Monthly Income
            </Typography>
            <Typography variant="h5" color="primary">
              {currency.symbol}{totalMonthlyIncome.toFixed(2)}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box sx={{ p: 2, border: '1px solid #e0e0e0', borderRadius: 1 }}>
            <Typography variant="subtitle2" color="text.secondary">
              Total Annual Income
            </Typography>
            <Typography variant="h5" color="primary">
              {currency.symbol}{totalAnnualIncome.toFixed(2)}
            </Typography>
          </Box>
        </Grid>
      </Grid>

      {/* Income Sources List */}
      {incomeSources.length > 0 ? (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Source Name</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Frequency</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {incomeSources.map((income) => (
                <TableRow key={income.id}>
                  <TableCell>{income.name}</TableCell>
                  <TableCell>
                    {currency.symbol}{income.amount.toFixed(2)}
                  </TableCell>
                  <TableCell>{formatFrequency(income.frequency)}</TableCell>
                  <TableCell align="right">
                    <IconButton
                      size="small"
                      onClick={() => handleClickOpen(true, income)}
                    >
                      <Edit fontSize="small" />
                    </IconButton>
                    <IconButton
                      size="small"
                      color="error"
                      onClick={() => handleDelete(income.id)}
                    >
                      <Delete fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Box sx={{ textAlign: 'center', py: 3 }}>
          <Typography variant="body1" color="text.secondary">
            No income sources added yet.
          </Typography>
          <Button
            variant="outlined"
            startIcon={<Add />}
            onClick={() => handleClickOpen(false)}
            sx={{ mt: 2 }}
          >
            Add Your First Income Source
          </Button>
        </Box>
      )}

      {/* Add/Edit Income Dialog */}
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>
          {editMode ? 'Edit Income Source' : 'Add Income Source'}
        </DialogTitle>
        <DialogContent>
          <Box component="form" sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              label="Income Source Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              autoFocus
              placeholder="e.g., Primary Salary, Freelancing, Investments"
            />
            <TextField
              margin="normal"
              required
              fullWidth
              label="Amount"
              type="number"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">{currency.symbol}</InputAdornment>
                ),
              }}
            />
            <FormControl fullWidth margin="normal">
              <InputLabel id="frequency-label">Frequency</InputLabel>
              <Select
                labelId="frequency-label"
                value={frequency}
                label="Frequency"
                onChange={(e) => setFrequency(e.target.value as 'monthly' | 'annual' | 'one-time')}
              >
                <MenuItem value="monthly">Monthly</MenuItem>
                <MenuItem value="annual">Annual</MenuItem>
                <MenuItem value="one-time">One-time</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button 
            onClick={handleSave} 
            variant="contained"
            disabled={!name || !amount || parseFloat(amount) <= 0}
          >
            {editMode ? 'Update' : 'Add'}
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default IncomeManager;