import React, { useState, useEffect } from 'react';
import { 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper, 
  IconButton, 
  TextField, 
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Chip,
  Switch,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  InputAdornment,
  FormControlLabel,
  Grid,
  Badge,
  Avatar
} from '@mui/material';
import { 
  Delete as DeleteIcon, 
  Edit as EditIcon, 
  Visibility as VisibilityIcon, 
  VisibilityOff as VisibilityOffIcon,
  CalendarMonth,
  CalendarToday,
  Person,
  FilterList,
  Search,
  Clear
} from '@mui/icons-material';
import { useExpenses, Expense } from '../../contexts/ExpenseContext';

const ExpenseList: React.FC = () => {
  const { 
    expenses, 
    updateExpense,
    deleteExpense, 
    toggleExpenseStatus,
    toggleExpenseRepeatable,
    categories, 
    currency,
    familyMembers
  } = useExpenses();

  // Filters
  const [searchTerm, setSearchTerm] = useState('');
  const [categoryFilter, setCategoryFilter] = useState<string>('');
  const [familyMemberFilter, setFamilyMemberFilter] = useState<string>('');
  const [typeFilter, setTypeFilter] = useState<string>('');
  const [statusFilter, setStatusFilter] = useState<string>('all');
  const [repeatableFilter, setRepeatableFilter] = useState<string>('all');

  // Edit dialog
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [currentExpense, setCurrentExpense] = useState<Expense | null>(null);
  const [editDescription, setEditDescription] = useState('');
  const [editAmount, setEditAmount] = useState('');
  const [editDate, setEditDate] = useState('');
  const [editCategory, setEditCategory] = useState('');
  const [editAssignedTo, setEditAssignedTo] = useState('');
  const [editIsEnabled, setEditIsEnabled] = useState(true);
  const [editIsSpreadAcrossMonths, setEditIsSpreadAcrossMonths] = useState(false);
  const [editRepeatable, setEditRepeatable] = useState(false);

  // Filter dialog
  const [filterDialogOpen, setFilterDialogOpen] = useState(false);

  const handleCategoryChange = (event: SelectChangeEvent) => {
    setCategoryFilter(event.target.value);
  };

  const handleFamilyMemberChange = (event: SelectChangeEvent) => {
    setFamilyMemberFilter(event.target.value);
  };

  const handleTypeChange = (event: SelectChangeEvent) => {
    setTypeFilter(event.target.value);
  };

  const handleStatusChange = (event: SelectChangeEvent) => {
    setStatusFilter(event.target.value);
  };
  
  const handleRepeatableChange = (event: SelectChangeEvent) => {
    setRepeatableFilter(event.target.value);
  };

  const handleToggleStatus = (expense: Expense) => {
    toggleExpenseStatus(expense.id);
  };
  
  const handleToggleRepeatable = (expense: Expense) => {
    // Only allow toggling repeatable for regular expenses
    if (expense.type === 'regular') {
      toggleExpenseRepeatable(expense.id);
    }
  };

  // Filter expenses based on all filters
  const filteredExpenses = expenses.filter(expense => {
    // Search term filter
    const matchesSearch = expense.description.toLowerCase().includes(searchTerm.toLowerCase());
    
    // Category filter
    const matchesCategory = categoryFilter ? expense.category === categoryFilter : true;
    
    // Family member filter
    const matchesFamilyMember = familyMemberFilter ? expense.assignedTo === familyMemberFilter : true;
    
    // Type filter
    const matchesType = typeFilter ? expense.type === typeFilter : true;
    
    // Status filter
    const matchesStatus = 
      statusFilter === 'all' ? true : 
      statusFilter === 'enabled' ? expense.isEnabled : 
      !expense.isEnabled;
    
    // Repeatable filter
    const matchesRepeatable = 
      repeatableFilter === 'all' ? true :
      repeatableFilter === 'repeatable' ? expense.repeatable === true :
      expense.repeatable !== true;
      
    return matchesSearch && matchesCategory && matchesFamilyMember && matchesType && matchesStatus && matchesRepeatable;
  });

  // Clear all filters
  const clearFilters = () => {
    setSearchTerm('');
    setCategoryFilter('');
    setFamilyMemberFilter('');
    setTypeFilter('');
    setStatusFilter('all');
    setRepeatableFilter('all');
    setFilterDialogOpen(false);
  };

  // Format date for display
  const formatDate = (date: Date) => {
    return new Date(date).toLocaleDateString();
  };

  // Open edit dialog
  const handleEditClick = (expense: Expense) => {
    setCurrentExpense(expense);
    setEditDescription(expense.description);
    setEditAmount(expense.amount.toString());
    setEditDate(new Date(expense.date).toISOString().split('T')[0]);
    setEditCategory(expense.category);
    setEditAssignedTo(expense.assignedTo || '1');
    setEditIsEnabled(expense.isEnabled);
    setEditIsSpreadAcrossMonths(expense.isSpreadAcrossMonths || false);
    setEditRepeatable(expense.repeatable || false);
    setEditDialogOpen(true);
  };

  // Save edited expense
  const handleSaveEdit = () => {
    if (currentExpense) {
      updateExpense(currentExpense.id, {
        description: editDescription,
        amount: parseFloat(editAmount),
        date: new Date(editDate),
        category: editCategory,
        assignedTo: editAssignedTo,
        isEnabled: editIsEnabled,
        repeatable: currentExpense.type === 'regular' ? editRepeatable : false, // Only regular expenses can be repeatable
        isSpreadAcrossMonths: editIsSpreadAcrossMonths
      });
      setEditDialogOpen(false);
    }
  };

  // Get family member name by ID
  const getFamilyMemberName = (id?: string) => {
    if (!id) return 'Self';
    const member = familyMembers.find(m => m.id === id);
    return member ? member.name : 'Self';
  };

  // Get active filter count for badge
  const getActiveFilterCount = () => {
    let count = 0;
    if (categoryFilter) count++;
    if (familyMemberFilter) count++;
    if (typeFilter) count++;
    if (statusFilter !== 'all') count++;
    if (repeatableFilter !== 'all') count++;
    return count;
  };

  return (
    <Box>
      {/* Search and Filter Bar */}
      <Box sx={{ mb: 3, display: 'flex', flexWrap: 'wrap', gap: 2, alignItems: 'center' }}>
        <TextField
          label="Search Expenses"
          variant="outlined"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          sx={{ flexGrow: 1, minWidth: '200px' }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
            endAdornment: searchTerm && (
              <InputAdornment position="end">
                <IconButton size="small" onClick={() => setSearchTerm('')}>
                  <Clear fontSize="small" />
                </IconButton>
              </InputAdornment>
            )
          }}
        />
        
        <Box>
          <Badge badgeContent={getActiveFilterCount()} color="primary">
            <Button 
              variant="outlined" 
              startIcon={<FilterList />}
              onClick={() => setFilterDialogOpen(true)}
            >
              Filters
            </Button>
          </Badge>
        </Box>
      </Box>
      
      {/* Expense Table */}
      {filteredExpenses.length > 0 ? (
        <TableContainer component={Paper}>
          <Table aria-label="expense table" size="small">
            <TableHead>
              <TableRow>
                <TableCell>Description</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Category</TableCell>
                <TableCell>Assigned To</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Repeatable</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredExpenses.map((expense) => (
                <TableRow 
                  key={expense.id}
                  sx={{
                    '&': { opacity: expense.isEnabled ? 1 : 0.6 }
                  }}
                >
                  <TableCell>{expense.description}</TableCell>
                  <TableCell>{currency.symbol}{expense.amount.toFixed(2)}</TableCell>
                  <TableCell>{formatDate(expense.date)}</TableCell>
                  <TableCell>
                    <Chip 
                      label={expense.category} 
                      color="primary" 
                      variant="outlined" 
                      size="small"
                    />
                  </TableCell>
                  <TableCell>
                    <Chip
                      avatar={
                        <Avatar sx={{ bgcolor: 'primary.main' }}>
                          {getFamilyMemberName(expense.assignedTo).charAt(0)}
                        </Avatar>
                      }
                      label={getFamilyMemberName(expense.assignedTo)}
                      variant="outlined"
                      size="small"
                    />
                  </TableCell>
                  <TableCell>
                    <Tooltip 
                      title={
                        expense.type === 'annual' && expense.isSpreadAcrossMonths 
                          ? 'Spread across months' 
                          : expense.type === 'regular' && expense.repeatable 
                          ? 'Repeats monthly' 
                          : ''
                      }
                    >
                      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
                        <Chip 
                          icon={expense.type === 'annual' ? <CalendarMonth fontSize="small" /> : <CalendarToday fontSize="small" />}
                          label={expense.type === 'annual' ? 'Annual' : 'Regular'}
                          color={expense.type === 'annual' ? 'secondary' : 'default'}
                          variant="outlined"
                          size="small"
                        />
                        {expense.type === 'regular' && expense.repeatable && (
                          <Chip 
                            label="Repeatable"
                            color="info"
                            variant="outlined"
                            size="small"
                          />
                        )}
                      </Box>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <Switch
                      checked={expense.isEnabled}
                      onChange={() => handleToggleStatus(expense)}
                      size="small"
                    />
                  </TableCell>
                  <TableCell>
                    {expense.type === 'regular' ? (
                      <Tooltip title={
                        expense.isEnabled 
                          ? (expense.repeatable 
                            ? "This expense repeats monthly from the date" 
                            : "Click to make this expense repeat monthly")
                          : "Enable the expense first"
                      }>
                        <span>
                          <Switch
                            checked={expense.repeatable === true}
                            onChange={() => handleToggleRepeatable(expense)}
                            size="small"
                            color="secondary"
                            disabled={!expense.isEnabled}
                          />
                        </span>
                      </Tooltip>
                    ) : (
                      <Typography variant="caption" color="text.secondary">N/A</Typography>
                    )}
                  </TableCell>
                  <TableCell align="right">
                    <IconButton 
                      size="small"
                      aria-label="edit"
                      onClick={() => handleEditClick(expense)}
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                    <IconButton 
                      size="small"
                      aria-label="delete"
                      onClick={() => deleteExpense(expense.id)}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Paper sx={{ p: 3, textAlign: 'center' }}>
          <Typography variant="body1" color="text.secondary">
            No expenses found matching your filters.
          </Typography>
          {(searchTerm || categoryFilter || familyMemberFilter || typeFilter || statusFilter !== 'all') && (
            <Button 
              startIcon={<Clear />} 
              onClick={clearFilters}
              sx={{ mt: 2 }}
            >
              Clear Filters
            </Button>
          )}
        </Paper>
      )}

      {/* Filter Dialog */}
      <Dialog 
        open={filterDialogOpen} 
        onClose={() => setFilterDialogOpen(false)}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>Filter Expenses</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="category-filter-label">Category</InputLabel>
                <Select
                  labelId="category-filter-label"
                  id="category-filter"
                  value={categoryFilter}
                  label="Category"
                  onChange={handleCategoryChange}
                >
                  <MenuItem value="">All Categories</MenuItem>
                  {categories.map((category) => (
                    <MenuItem key={category} value={category}>
                      {category}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="family-filter-label">Family Member</InputLabel>
                <Select
                  labelId="family-filter-label"
                  id="family-filter"
                  value={familyMemberFilter}
                  label="Family Member"
                  onChange={handleFamilyMemberChange}
                >
                  <MenuItem value="">All Family Members</MenuItem>
                  {familyMembers.map((member) => (
                    <MenuItem key={member.id} value={member.id}>
                      {member.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="type-filter-label">Expense Type</InputLabel>
                <Select
                  labelId="type-filter-label"
                  id="type-filter"
                  value={typeFilter}
                  label="Expense Type"
                  onChange={handleTypeChange}
                >
                  <MenuItem value="">All Types</MenuItem>
                  <MenuItem value="regular">Regular Expenses</MenuItem>
                  <MenuItem value="annual">Annual Expenses</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="status-filter-label">Status</InputLabel>
                <Select
                  labelId="status-filter-label"
                  id="status-filter"
                  value={statusFilter}
                  label="Status"
                  onChange={handleStatusChange}
                >
                  <MenuItem value="all">All Expenses</MenuItem>
                  <MenuItem value="enabled">Enabled Only</MenuItem>
                  <MenuItem value="disabled">Disabled Only</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="repeatable-filter-label">Repeatable</InputLabel>
                <Select
                  labelId="repeatable-filter-label"
                  id="repeatable-filter"
                  value={repeatableFilter}
                  label="Repeatable"
                  onChange={handleRepeatableChange}
                >
                  <MenuItem value="all">All Expenses</MenuItem>
                  <MenuItem value="repeatable">Repeatable Only</MenuItem>
                  <MenuItem value="non-repeatable">Non-Repeatable Only</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={clearFilters}>Clear Filters</Button>
          <Button onClick={() => setFilterDialogOpen(false)} variant="contained">Apply</Button>
        </DialogActions>
      </Dialog>

      {/* Edit Expense Dialog */}
      <Dialog
        open={editDialogOpen}
        onClose={() => setEditDialogOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Edit Expense</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12}>
              <TextField
                required
                label="Description"
                fullWidth
                value={editDescription}
                onChange={(e) => setEditDescription(e.target.value)}
              />
            </Grid>
            
            <Grid item xs={12} sm={6}>
              <TextField
                required
                label="Amount"
                type="number"
                fullWidth
                value={editAmount}
                onChange={(e) => setEditAmount(e.target.value)}
                InputProps={{
                  startAdornment: <InputAdornment position="start">{currency.symbol}</InputAdornment>,
                }}
              />
            </Grid>
            
            <Grid item xs={12} sm={6}>
              <TextField
                required
                label="Date"
                type="date"
                fullWidth
                value={editDate}
                onChange={(e) => setEditDate(e.target.value)}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Category</InputLabel>
                <Select
                  value={editCategory}
                  label="Category"
                  onChange={(e) => setEditCategory(e.target.value)}
                >
                  {categories.map((cat) => (
                    <MenuItem key={cat} value={cat}>
                      {cat}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Assigned To</InputLabel>
                <Select
                  value={editAssignedTo}
                  label="Assigned To"
                  onChange={(e) => setEditAssignedTo(e.target.value)}
                >
                  {familyMembers.map((member) => (
                    <MenuItem key={member.id} value={member.id}>
                      {member.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={editIsEnabled}
                    onChange={(e) => setEditIsEnabled(e.target.checked)}
                  />
                }
                label="Include in budget calculations"
              />
            </Grid>
            
            {currentExpense?.type === 'regular' && (
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={editRepeatable}
                      onChange={(e) => setEditRepeatable(e.target.checked)}
                    />
                  }
                  label="Repeatable expense"
                />
                {editRepeatable && (
                  <Typography variant="caption" color="text.secondary" display="block">
                    This expense will be included in every month starting from the date.
                  </Typography>
                )}
              </Grid>
            )}
            
            {currentExpense?.type === 'annual' && (
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={editIsSpreadAcrossMonths}
                      onChange={(e) => setEditIsSpreadAcrossMonths(e.target.checked)}
                    />
                  }
                  label="Spread across monthly budget"
                />
                {editIsSpreadAcrossMonths && (
                  <Typography variant="caption" color="text.secondary" display="block">
                    This expense will be divided by 12 and included in your monthly budget calculations.
                  </Typography>
                )}
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditDialogOpen(false)}>Cancel</Button>
          <Button 
            onClick={handleSaveEdit} 
            variant="contained"
            disabled={!editDescription || !editAmount || !editDate || !editCategory}
          >
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ExpenseList;