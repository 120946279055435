import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { useAuth } from './AuthContext';
import { 
  expensesCollection, 
  incomeCollection, 
  familyMembersCollection, 
  categoriesCollection,
  userSettingsCollection,
  addDoc,
  setDoc,
  updateDoc,
  deleteDoc,
  getDocs,
  doc,
  query,
  where,
  Timestamp,
  db,
  convertTimestampToDate,
  writeBatch
} from '../firebase';

// Currency types
export interface Currency {
  code: string;
  symbol: string;
  name: string;
}

// Available currencies
export const currencies: Currency[] = [
  { code: 'USD', symbol: '$', name: 'US Dollar' },
  { code: 'EUR', symbol: '€', name: 'Euro' },
  { code: 'AED', symbol: 'د.إ', name: 'Emirati Dirham' },
  { code: 'TND', symbol: 'د.ت', name: 'Tunisian Dinar' },
  { code: 'CNY', symbol: '¥', name: 'Chinese Yuan' },
  { code: 'JPY', symbol: '¥', name: 'Japanese Yen' }
];

// Define types
export interface Expense {
  id: string;
  description: string;
  amount: number;
  date: Date;
  category: string;
  assignedTo?: string; // Family member
  isEnabled: boolean;
  type: 'regular' | 'annual';
  frequency?: 'monthly' | 'annual' | 'one-time';
  isSpreadAcrossMonths?: boolean; // For annual expenses
  repeatable?: boolean; // If true, the expense is included in every month from the start date
}

export interface IncomeSource {
  id: string;
  name: string;
  amount: number;
  frequency: 'monthly' | 'annual' | 'one-time';
  date?: Date; // For one-time income
}

export interface FamilyMember {
  id: string;
  name: string;
}

interface BudgetInfo {
  incomes: IncomeSource[];
}

interface ExpenseContextType {
  // Expenses
  expenses: Expense[];
  addExpense: (expense: Omit<Expense, 'id'>) => void;
  updateExpense: (id: string, expense: Partial<Expense>) => void;
  deleteExpense: (id: string) => void;
  toggleExpenseStatus: (id: string) => void;
  toggleExpenseRepeatable: (id: string) => void;
  getCurrentMonthExpenses: () => Expense[];
  getAnnualExpenses: () => Expense[];
  getExpensesByFamilyMember: (memberId: string) => Expense[];
  getMonthlyEquivalentOfAnnualExpenses: () => number;
  getTotalMonthlyExpenses: () => number;
  getTotalMonthlyExpensesByMember: (memberId: string) => number;
  getRegularMonthlyExpenses: () => number;
  getProratedAnnualExpenses: () => number;
  
  // Categories
  categories: string[];
  addCategory: (category: string) => void;
  updateCategory: (oldName: string, newName: string) => void;
  deleteCategory: (name: string) => void;
  
  // Income
  incomeSources: IncomeSource[];
  addIncomeSource: (income: Omit<IncomeSource, 'id'>) => void;
  updateIncomeSource: (id: string, income: Partial<IncomeSource>) => void;
  deleteIncomeSource: (id: string) => void;
  getTotalMonthlyIncome: () => number;
  getTotalAnnualIncome: () => number;
  
  // Family Members
  familyMembers: FamilyMember[];
  addFamilyMember: (member: Omit<FamilyMember, 'id'>) => void;
  updateFamilyMember: (id: string, member: Partial<FamilyMember>) => void;
  deleteFamilyMember: (id: string) => void;
  
  // Budget & Financial Insights
  getMonthlyBalance: () => number;
  getBudgetStatus: () => 'good' | 'warning' | 'danger';
  getExpensePercentage: () => number;
  getTotalAnnualExpenses: () => number;
  getAnnualBalance: () => number;
  
  // Settings
  currency: Currency;
  setCurrency: (currency: Currency) => void;
  clearData: () => void;
}

// Create context
const ExpenseContext = createContext<ExpenseContextType | undefined>(undefined);

// Sample categories
const defaultCategories = [
  'Food', 
  'Transportation', 
  'Entertainment', 
  'Housing', 
  'Utilities', 
  'Healthcare', 
  'Shopping',
  'Health Insurance',
  'Car Insurance',
  'Home Insurance',
  'Life Insurance',
  'Travel Insurance',
  'Subscriptions',
  'Education',
  'Taxes',
  'Other'
];

// Default family members
const defaultFamilyMembers: FamilyMember[] = [
  { id: '1', name: 'Self' }
];

// Default currency
const defaultCurrency = currencies[0]; // USD

// Provider component
export const ExpenseProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { currentUser } = useAuth();
  const [expenses, setExpenses] = useState<Expense[]>([]);
  const [categories, setCategories] = useState<string[]>(defaultCategories);
  const [incomeSources, setIncomeSources] = useState<IncomeSource[]>([]);
  const [familyMembers, setFamilyMembers] = useState<FamilyMember[]>(defaultFamilyMembers);
  const [currency, setCurrency] = useState<Currency>(defaultCurrency);

  // Load data from Firestore when user logs in
  useEffect(() => {
    if (currentUser) {
      const userId = currentUser.uid;
      
      // Set loading state if needed
      // setLoading(true);
      
      const fetchData = async () => {
        try {
          // Get user expenses
          const expensesQuery = query(expensesCollection, where("userId", "==", userId));
          const expensesSnapshot = await getDocs(expensesQuery);
          const expensesData = expensesSnapshot.docs.map(doc => {
            const data = doc.data();
            // Convert Firestore timestamps to Date objects
            const expense = {
              ...data,
              id: doc.id,
              date: data.date ? convertTimestampToDate(data.date) : new Date(),
              // Ensure fields have default values if they don't exist
              isEnabled: data.isEnabled !== undefined ? data.isEnabled : true,
              type: data.type || 'regular',
              frequency: data.frequency || 'monthly',
              description: data.description || '',
              amount: data.amount || 0,
              category: data.category || 'Other',
              assignedTo: data.assignedTo || '1', // Default to "Self" if not set
              repeatable: data.repeatable !== undefined ? data.repeatable : false // Default to non-repeatable
            } as Expense;
            
            // Debug expense data during load
            console.log(`Loading expense: ${expense.description}`, {
              id: expense.id,
              amount: expense.amount, 
              type: expense.type,
              isEnabled: expense.isEnabled,
              date: expense.date instanceof Date ? expense.date.toISOString() : expense.date
            });
            
            return expense;
          });
          setExpenses(expensesData);
          
          // Get income sources
          const incomeQuery = query(incomeCollection, where("userId", "==", userId));
          const incomeSnapshot = await getDocs(incomeQuery);
          const incomeData = incomeSnapshot.docs.map(doc => {
            const data = doc.data();
            return {
              ...data,
              id: doc.id,
              name: data.name || '',
              amount: data.amount || 0,
              frequency: (data.frequency || 'monthly') as 'monthly' | 'annual' | 'one-time',
              date: data.date ? convertTimestampToDate(data.date) : undefined
            } as IncomeSource;
          });
          
          if (incomeData.length > 0) {
            setIncomeSources(incomeData);
          } else {
            // Create default income source if none exists
            const defaultIncome = {
              id: 'default',
              name: 'Primary Salary',
              amount: 0,
              frequency: 'monthly' as 'monthly' | 'annual' | 'one-time',
              userId
            };
            
            // Add to Firestore
            const docRef = await addDoc(incomeCollection, defaultIncome);
            
            // Set in state with the generated ID
            setIncomeSources([{
              ...defaultIncome,
              id: docRef.id
            } as IncomeSource]);
          }
          
          // Get family members
          const familyQuery = query(familyMembersCollection, where("userId", "==", userId));
          const familySnapshot = await getDocs(familyQuery);
          const familyData = familySnapshot.docs.map(doc => ({
            ...doc.data(),
            id: doc.id
          }));
          
          if (familyData.length > 0) {
            setFamilyMembers(familyData as FamilyMember[]);
          } else {
            // Create default family member if none exists
            const defaultMember = {
              id: 'default',
              name: 'Self',
              userId
            };
            
            // Add to Firestore
            const docRef = await addDoc(familyMembersCollection, defaultMember);
            
            // Set in state with the generated ID
            setFamilyMembers([{
              ...defaultMember,
              id: docRef.id
            }] as FamilyMember[]);
          }
          
          // Get categories
          const categoriesQuery = query(categoriesCollection, where("userId", "==", userId));
          const categoriesSnapshot = await getDocs(categoriesQuery);
          if (categoriesSnapshot.docs.length > 0) {
            // User has custom categories stored
            const categoriesData = categoriesSnapshot.docs[0].data().categories || [];
            setCategories(categoriesData);
          } else {
            // Create default categories in Firestore
            await addDoc(categoriesCollection, {
              userId,
              categories: defaultCategories
            });
            setCategories(defaultCategories);
          }
          
          // Get user preferences including currency
          const settingsQuery = query(userSettingsCollection, where("userId", "==", userId));
          const settingsSnapshot = await getDocs(settingsQuery);
          if (settingsSnapshot.docs.length > 0) {
            const settings = settingsSnapshot.docs[0].data();
            if (settings.currency) {
              setCurrency(settings.currency);
            }
          } else {
            // Create default settings
            await addDoc(userSettingsCollection, {
              userId,
              currency: defaultCurrency
            });
          }
          
        } catch (error) {
          console.error('Error loading data from Firestore:', error);
        } finally {
          // setLoading(false);
        }
      };
      
      fetchData();
    } else {
      // Clear data if no user is logged in
      clearData();
    }
  }, [currentUser]);

  // We don't need to automatically save to Firestore on every state change
  // Instead, we'll handle saving in our CRUD functions below

  // Expense Functions
  const addExpense = async (expense: Omit<Expense, 'id'>) => {
    if (!currentUser) return;
    
    try {
      // Prepare the expense data for Firestore
      const expenseDataForFirestore = {
        ...expense,
        userId: currentUser.uid,
        isEnabled: true, // Default to enabled
        type: expense.type || 'regular',
        frequency: expense.frequency || 'monthly',
        repeatable: expense.repeatable !== undefined ? expense.repeatable : false, // Default to non-repeatable
        createdAt: Timestamp.now()
      } as any;
      
      // Convert date to Firestore timestamp
      if (expense.date) {
        expenseDataForFirestore.date = Timestamp.fromDate(expense.date);
      } else {
        expenseDataForFirestore.date = Timestamp.fromDate(new Date());
      }
      
      // Add to Firestore
      const docRef = await addDoc(expensesCollection, expenseDataForFirestore);
      
      // Update the local state with the new expense
      const newExpense: Expense = {
        ...expense,
        id: docRef.id,
        date: expense.date || new Date(), // Keep as JS Date in state
        isEnabled: true,
        type: expense.type || 'regular',
        frequency: expense.frequency || 'monthly',
        repeatable: expense.repeatable !== undefined ? expense.repeatable : false
      };
      
      setExpenses([...expenses, newExpense]);
    } catch (error) {
      console.error('Error adding expense to Firestore:', error);
    }
  };

  const updateExpense = async (id: string, updatedExpense: Partial<Expense>) => {
    if (!currentUser) return;
    
    try {
      // Get reference to the expense document
      const expenseRef = doc(db, 'expenses', id);
      
      // Format the data for Firestore
      const updateData = { ...updatedExpense } as any;
      
      // Convert Date to Timestamp if it exists
      if (updateData.date) {
        updateData.date = Timestamp.fromDate(updateData.date);
      }
      
      // Update in Firestore
      await updateDoc(expenseRef, updateData);
      
      // Update in local state
      setExpenses(expenses.map(expense => 
        expense.id === id ? { 
          ...expense, 
          ...updatedExpense,
          // Ensure date is a JS Date in our state
          date: updatedExpense.date || expense.date
        } : expense
      ));
    } catch (error) {
      console.error('Error updating expense in Firestore:', error);
    }
  };

  const deleteExpense = async (id: string) => {
    if (!currentUser) return;
    
    try {
      // Delete from Firestore
      const expenseRef = doc(db, 'expenses', id);
      await deleteDoc(expenseRef);
      
      // Remove from local state
      setExpenses(expenses.filter(expense => expense.id !== id));
    } catch (error) {
      console.error('Error deleting expense from Firestore:', error);
    }
  };
  
  const toggleExpenseStatus = async (id: string) => {
    if (!currentUser) return;
    
    try {
      // Find the expense to toggle
      const expenseToToggle = expenses.find(expense => expense.id === id);
      if (!expenseToToggle) return;
      
      // Toggle status in Firestore
      const expenseRef = doc(db, 'expenses', id);
      await updateDoc(expenseRef, {
        isEnabled: !expenseToToggle.isEnabled
      });
      
      // Update local state
      setExpenses(expenses.map(expense => 
        expense.id === id ? { ...expense, isEnabled: !expense.isEnabled } : expense
      ));
    } catch (error) {
      console.error('Error toggling expense status in Firestore:', error);
    }
  };
  
  const toggleExpenseRepeatable = async (id: string) => {
    if (!currentUser) return;
    
    try {
      // Find the expense to toggle
      const expenseToToggle = expenses.find(expense => expense.id === id);
      if (!expenseToToggle) return;
      
      // Only allow toggling repeatable for regular expenses
      if (expenseToToggle.type !== 'regular') return;
      
      // Toggle repeatable in Firestore
      const expenseRef = doc(db, 'expenses', id);
      await updateDoc(expenseRef, {
        repeatable: !expenseToToggle.repeatable
      });
      
      // Update local state
      setExpenses(expenses.map(expense => 
        expense.id === id ? { ...expense, repeatable: !expense.repeatable } : expense
      ));
    } catch (error) {
      console.error('Error toggling expense repeatable status in Firestore:', error);
    }
  };

  // Get all enabled expenses for the current month - ONLY REGULAR EXPENSES
  // Annual expenses are handled separately via getMonthlyEquivalentOfAnnualExpenses
  const getCurrentMonthExpenses = () => {
    const now = new Date();
    const currentMonth = now.getMonth();
    const currentYear = now.getFullYear();
    
    // Return only REGULAR expenses for the current month
    const result = expenses.filter((expense) => {
      // Skip disabled expenses
      if (!expense.isEnabled) return false;
      
      // For regular expenses, check if they are in the current month
      if (expense.type === 'regular') {
        const expenseDate = new Date(expense.date);
        return expenseDate.getMonth() === currentMonth && 
               expenseDate.getFullYear() === currentYear;
      }
      
      // Don't include annual expenses here - they're handled separately
      return false;
    });
    
    return result;
  };
  
  // Get annual expenses
  const getAnnualExpenses = () => {
    return expenses.filter(expense => 
      expense.isEnabled && expense.type === 'annual'
    );
  };
  
  // Get expenses by family member (all expenses, both regular and annual)
  const getExpensesByFamilyMember = (memberId: string) => {
    return expenses.filter(expense => 
      expense.isEnabled && expense.assignedTo === memberId
    );
  };
  
  // Get total monthly expenses for a specific family member
  const getTotalMonthlyExpensesByMember = (memberId: string) => {
    // Get the current month and year
    const now = new Date();
    const currentMonth = now.getMonth();
    const currentYear = now.getFullYear();
    
    // Calculate regular expenses for this family member's current month expenses
    let regularExpenses = 0;
    
    // Calculate annual expenses prorated for this family member
    let annualExpenses = 0;
    
    // Process all expenses for this member
    expenses.forEach(expense => {
      // Skip disabled expenses and expenses not assigned to this member
      if (!expense.isEnabled || expense.assignedTo !== memberId) return;
      
      if (expense.type === 'regular') {
        // Ensure date is properly handled regardless of format
        const expenseDate = expense.date instanceof Date ? expense.date : new Date(expense.date);
        
        // Check if this expense should be included in the current month
        const isInCurrentMonth = expenseDate.getMonth() === currentMonth && 
                                expenseDate.getFullYear() === currentYear;
                                
        // Check if this is a repeatable expense that started in a previous month
        const isRepeatableFromPreviousMonth = expense.repeatable === true && 
                                            (expenseDate < now);
        
        // Include if it's a current month expense OR a repeatable expense from a previous month
        if (isInCurrentMonth || isRepeatableFromPreviousMonth) {
          regularExpenses += expense.amount;
        }
      }
      else if (expense.type === 'annual' && expense.isSpreadAcrossMonths) {
        // Prorate annual expenses
        annualExpenses += expense.amount / 12;
      }
    });
    
    // Return the total
    return regularExpenses + annualExpenses;
  };
  
  // Get monthly equivalent of annual expenses (for those spread across months)
  const getMonthlyEquivalentOfAnnualExpenses = () => {
    // Get all enabled annual expenses that are set to be spread across months
    const annualExpenses = expenses.filter(expense => 
      expense.isEnabled && 
      expense.type === 'annual' && 
      expense.isSpreadAcrossMonths === true
    );
    
    // Calculate the total amount of these annual expenses
    const total = annualExpenses.reduce((sum, expense) => sum + expense.amount, 0);
    
    // Return the monthly equivalent (total divided by 12)
    return total / 12;
  };
  
  // Category Functions
  const addCategory = async (category: string) => {
    if (!currentUser || categories.includes(category)) return;
    
    try {
      // Get the categories document for this user
      const categoriesQuery = query(categoriesCollection, where("userId", "==", currentUser.uid));
      const categoriesSnapshot = await getDocs(categoriesQuery);
      
      const newCategories = [...categories, category];
      
      if (categoriesSnapshot.docs.length > 0) {
        // Update existing categories document
        const categoryDoc = categoriesSnapshot.docs[0];
        await updateDoc(doc(db, 'categories', categoryDoc.id), {
          categories: newCategories
        });
      } else {
        // Create new categories document
        await addDoc(categoriesCollection, {
          userId: currentUser.uid,
          categories: newCategories
        });
      }
      
      // Update local state
      setCategories(newCategories);
    } catch (error) {
      console.error('Error saving category to Firestore:', error);
    }
  };
  
  const updateCategory = async (oldName: string, newName: string) => {
    if (!currentUser) return;
    
    try {
      // Get the categories document for this user
      const categoriesQuery = query(categoriesCollection, where("userId", "==", currentUser.uid));
      const categoriesSnapshot = await getDocs(categoriesQuery);
      
      if (categoriesSnapshot.docs.length > 0) {
        // Update the category name in the categories array
        const categoryDoc = categoriesSnapshot.docs[0];
        const updatedCategories = categories.map(cat => (cat === oldName ? newName : cat));
        
        // Update in Firestore
        await updateDoc(doc(db, 'categories', categoryDoc.id), {
          categories: updatedCategories
        });
        
        // Update local state
        setCategories(updatedCategories);
        
        // Now update all expenses that use this category
        // First get all user's expenses with this category
        const affectedExpensesQuery = query(
          expensesCollection, 
          where("userId", "==", currentUser.uid),
          where("category", "==", oldName)
        );
        
        const affectedExpensesSnapshot = await getDocs(affectedExpensesQuery);
        
        // Batch update all affected expenses
        const batch = writeBatch(db);
        affectedExpensesSnapshot.docs.forEach(expenseDoc => {
          const expenseRef = doc(db, 'expenses', expenseDoc.id);
          batch.update(expenseRef, { category: newName });
        });
        
        // Commit the batch
        await batch.commit();
        
        // Update local state for expenses
        setExpenses(expenses.map(expense => 
          expense.category === oldName 
            ? { ...expense, category: newName } 
            : expense
        ));
      }
    } catch (error) {
      console.error('Error updating category in Firestore:', error);
    }
  };
  
  const deleteCategory = async (name: string) => {
    if (!currentUser || defaultCategories.includes(name)) return;
    
    try {
      // Get the categories document for this user
      const categoriesQuery = query(categoriesCollection, where("userId", "==", currentUser.uid));
      const categoriesSnapshot = await getDocs(categoriesQuery);
      
      if (categoriesSnapshot.docs.length > 0) {
        // Remove the category from the categories array
        const categoryDoc = categoriesSnapshot.docs[0];
        const updatedCategories = categories.filter(cat => cat !== name);
        
        // Update in Firestore
        await updateDoc(doc(db, 'categories', categoryDoc.id), {
          categories: updatedCategories
        });
        
        // Update local state
        setCategories(updatedCategories);
        
        // Now update all expenses that use this category to "Other"
        // First get all user's expenses with this category
        const affectedExpensesQuery = query(
          expensesCollection, 
          where("userId", "==", currentUser.uid),
          where("category", "==", name)
        );
        
        const affectedExpensesSnapshot = await getDocs(affectedExpensesQuery);
        
        // Batch update all affected expenses
        const batch = writeBatch(db);
        affectedExpensesSnapshot.docs.forEach(expenseDoc => {
          const expenseRef = doc(db, 'expenses', expenseDoc.id);
          batch.update(expenseRef, { category: 'Other' });
        });
        
        // Commit the batch
        await batch.commit();
        
        // Update local state for expenses
        setExpenses(expenses.map(expense => 
          expense.category === name 
            ? { ...expense, category: 'Other' } 
            : expense
        ));
      }
    } catch (error) {
      console.error('Error deleting category in Firestore:', error);
    }
  };
  
  // Income Functions
  const addIncomeSource = async (income: Omit<IncomeSource, 'id'>) => {
    if (!currentUser) return;
    
    try {
      // Prepare the income data for Firestore
      const incomeDataForFirestore = {
        ...income,
        userId: currentUser.uid,
        createdAt: Timestamp.now()
      } as any;
      
      // Convert date to Firestore timestamp if it exists
      if (income.date) {
        incomeDataForFirestore.date = Timestamp.fromDate(income.date);
      }
      
      // Add to Firestore
      const docRef = await addDoc(incomeCollection, incomeDataForFirestore);
      
      // Update the local state with the new income source
      const newIncome: IncomeSource = {
        ...income,
        id: docRef.id,
        // Keep as JS Date in state (ensure date is preserved)
        date: income.date
      };
      
      setIncomeSources([...incomeSources, newIncome]);
    } catch (error) {
      console.error('Error adding income source to Firestore:', error);
    }
  };
  
  const updateIncomeSource = async (id: string, updatedIncome: Partial<IncomeSource>) => {
    if (!currentUser) return;
    
    try {
      // Get reference to the income document
      const incomeRef = doc(db, 'income', id);
      
      // Format the data for Firestore
      const updateData = { ...updatedIncome } as any;
      
      // Convert Date to Timestamp if it exists
      if (updateData.date) {
        updateData.date = Timestamp.fromDate(updateData.date);
      }
      
      // Update in Firestore
      await updateDoc(incomeRef, updateData);
      
      // Update in local state
      setIncomeSources(incomeSources.map(income => 
        income.id === id ? { 
          ...income, 
          ...updatedIncome,
          // Ensure date is a JS Date in our state
          date: updatedIncome.date || income.date
        } : income
      ));
    } catch (error) {
      console.error('Error updating income source in Firestore:', error);
    }
  };
  
  const deleteIncomeSource = async (id: string) => {
    if (!currentUser) return;
    
    try {
      // Delete from Firestore
      const incomeRef = doc(db, 'income', id);
      await deleteDoc(incomeRef);
      
      // Remove from local state
      setIncomeSources(incomeSources.filter(income => income.id !== id));
    } catch (error) {
      console.error('Error deleting income source from Firestore:', error);
    }
  };
  
  const getTotalMonthlyIncome = () => {
    return incomeSources.reduce((total, income) => {
      if (income.frequency === 'monthly') {
        return total + income.amount;
      } else if (income.frequency === 'annual') {
        return total + (income.amount / 12);
      }
      return total;
    }, 0);
  };
  
  const getTotalAnnualIncome = () => {
    return incomeSources.reduce((total, income) => {
      if (income.frequency === 'annual') {
        return total + income.amount;
      } else if (income.frequency === 'monthly') {
        return total + (income.amount * 12);
      }
      return total;
    }, 0);
  };
  
  // Family Member Functions
  const addFamilyMember = async (member: Omit<FamilyMember, 'id'>) => {
    if (!currentUser) return;
    
    try {
      // Prepare the family member data for Firestore
      const memberData = {
        ...member,
        userId: currentUser.uid,
        createdAt: Timestamp.now()
      };
      
      // Add to Firestore
      const docRef = await addDoc(familyMembersCollection, memberData);
      
      // Update the local state with the new family member
      const newMember = {
        ...memberData,
        id: docRef.id
      } as FamilyMember;
      
      setFamilyMembers([...familyMembers, newMember]);
    } catch (error) {
      console.error('Error adding family member to Firestore:', error);
    }
  };
  
  const updateFamilyMember = async (id: string, updatedMember: Partial<FamilyMember>) => {
    if (!currentUser) return;
    
    try {
      // Get reference to the family member document
      const memberRef = doc(db, 'familyMembers', id);
      
      // Update in Firestore
      await updateDoc(memberRef, updatedMember);
      
      // Update in local state
      setFamilyMembers(familyMembers.map(member => 
        member.id === id ? { ...member, ...updatedMember } : member
      ));
    } catch (error) {
      console.error('Error updating family member in Firestore:', error);
    }
  };
  
  const deleteFamilyMember = async (id: string) => {
    // Don't delete the default "Self" member
    if (!currentUser || id === familyMembers[0].id) return;
    
    try {
      // Delete from Firestore
      const memberRef = doc(db, 'familyMembers', id);
      await deleteDoc(memberRef);
      
      // Find the default family member (Self)
      const defaultMember = familyMembers.find(m => m.name === 'Self') || familyMembers[0];
      
      // Update all expenses assigned to this family member to the default member
      const affectedExpensesQuery = query(
        expensesCollection, 
        where("userId", "==", currentUser.uid),
        where("assignedTo", "==", id)
      );
      
      const affectedExpensesSnapshot = await getDocs(affectedExpensesQuery);
      
      // Batch update all affected expenses
      const batch = writeBatch(db);
      affectedExpensesSnapshot.docs.forEach(expenseDoc => {
        const expenseRef = doc(db, 'expenses', expenseDoc.id);
        batch.update(expenseRef, { assignedTo: defaultMember.id });
      });
      
      // Commit the batch
      await batch.commit();
      
      // Remove from local state
      setFamilyMembers(familyMembers.filter(member => member.id !== id));
      
      // Update expenses with this family member to the default member
      setExpenses(expenses.map(expense => 
        expense.assignedTo === id 
          ? { ...expense, assignedTo: defaultMember.id } 
          : expense
      ));
    } catch (error) {
      console.error('Error deleting family member from Firestore:', error);
    }
  };

  // Update currency setting and save to Firestore
  const setCurrencyAndSave = async (newCurrency: Currency) => {
    if (!currentUser) {
      setCurrency(newCurrency);
      return;
    }
    
    try {
      // Get the user settings document for this user
      const settingsQuery = query(userSettingsCollection, where("userId", "==", currentUser.uid));
      const settingsSnapshot = await getDocs(settingsQuery);
      
      if (settingsSnapshot.docs.length > 0) {
        // Update existing settings document
        const settingsDoc = settingsSnapshot.docs[0];
        await updateDoc(doc(db, 'userSettings', settingsDoc.id), {
          currency: newCurrency
        });
      } else {
        // Create new settings document
        await addDoc(userSettingsCollection, {
          userId: currentUser.uid,
          currency: newCurrency
        });
      }
      
      // Update local state
      setCurrency(newCurrency);
    } catch (error) {
      console.error('Error saving currency setting to Firestore:', error);
    }
  };
  
  // Clear all data (used for logout)
  const clearData = () => {
    setExpenses([]);
    setIncomeSources([]);
    setFamilyMembers(defaultFamilyMembers);
    setCurrency(defaultCurrency);
    setCategories(defaultCategories);
  };

  // Calculate TOTAL regular expenses (for current month, all family members)
  const getRegularMonthlyExpenses = () => {
    // Get the current month and year for filtering
    const now = new Date();
    const currentMonth = now.getMonth();
    const currentYear = now.getFullYear();
    
    // Array to store all expenses that should be included in this month
    let currentMonthExpenses: Expense[] = [];
    
    // Process all regular expenses
    expenses.forEach(expense => {
      // Skip disabled expenses
      if (!expense.isEnabled || expense.type !== 'regular') return;
      
      // Ensure date is properly handled regardless of format
      const expenseDate = expense.date instanceof Date ? expense.date : new Date(expense.date);
      
      // Check if this expense should be included in the current month
      const isInCurrentMonth = expenseDate.getMonth() === currentMonth && 
                              expenseDate.getFullYear() === currentYear;
                              
      // Check if this is a repeatable expense that started in a previous month
      const isRepeatableFromPreviousMonth = expense.repeatable === true && 
                                          (expenseDate < now);
      
      // Include if it's a current month expense OR a repeatable expense from a previous month
      if (isInCurrentMonth || isRepeatableFromPreviousMonth) {
        currentMonthExpenses.push(expense);
      }
    });
    
    // Calculate total using reduce
    const total = currentMonthExpenses.reduce((sum, expense) => sum + expense.amount, 0);
    
    // DETAILED DEBUG INFO
    console.log('DEBUG - REGULAR EXPENSES:');
    console.log('- Current Month:', currentMonth + 1); // +1 for human-readable month
    console.log('- Current Year:', currentYear);
    console.log('- All Regular Expenses Count:', expenses.filter(e => e.type === 'regular').length);
    console.log('- Enabled Regular Expenses Count:', expenses.filter(e => e.isEnabled && e.type === 'regular').length);
    console.log('- Current Month Regular Expenses Count:', currentMonthExpenses.length);
    console.log('- Current Month Regular Expenses Total:', total);
    console.log('- Current Month Regular Expenses:', currentMonthExpenses.map(e => ({
      amount: e.amount,
      description: e.description,
      date: new Date(e.date).toISOString().slice(0, 10),
      type: e.type,
      enabled: e.isEnabled,
      repeatable: e.repeatable
    })));
    
    return total;
  };
  
  // Calculate TOTAL monthly portion of annual expenses (all family members)
  const getProratedAnnualExpenses = () => {
    // Explicitly filter annual expenses that should be spread
    const spreadAnnualExpenses = expenses.filter(e => 
      e.isEnabled && 
      e.type === 'annual' && 
      e.isSpreadAcrossMonths === true
    );
    
    // Calculate total annual amount
    const totalAnnual = spreadAnnualExpenses.reduce((sum, expense) => sum + expense.amount, 0);
    
    // Calculate monthly equivalent (annual ÷ 12)
    const totalMonthlyEquivalent = totalAnnual / 12;
    
    // DETAILED DEBUG INFO
    console.log('DEBUG - ANNUAL EXPENSES:');
    console.log('- All Annual Expenses Count:', expenses.filter(e => e.type === 'annual').length);
    console.log('- Enabled Annual Expenses Count:', expenses.filter(e => e.isEnabled && e.type === 'annual').length);
    console.log('- Spread Annual Expenses Count:', spreadAnnualExpenses.length);
    console.log('- Total Annual Amount:', totalAnnual);
    console.log('- Monthly Equivalent of Annual:', totalMonthlyEquivalent);
    console.log('- Spread Annual Expenses:', spreadAnnualExpenses.map(e => ({
      amount: e.amount,
      description: e.description,
      type: e.type,
      enabled: e.isEnabled,
      isSpreadAcrossMonths: e.isSpreadAcrossMonths
    })));
    
    return totalMonthlyEquivalent;
  };
  
  // Get total monthly expenses (regular + annual prorated) for all family members
  const getTotalMonthlyExpenses = () => {
    const regularExpenses = getRegularMonthlyExpenses();
    const annualExpenses = getProratedAnnualExpenses();
    
    // Debug the final values
    console.log('FINAL - Regular Monthly:', regularExpenses);
    console.log('FINAL - Prorated Annual:', annualExpenses);
    console.log('FINAL - Total Monthly:', regularExpenses + annualExpenses);
    
    // Return the total monthly expenses (regular + prorated annual)
    return regularExpenses + annualExpenses;
  };

  // Calculate monthly balance
  const getMonthlyBalance = () => {
    // Monthly balance = income - total expenses
    return getTotalMonthlyIncome() - getTotalMonthlyExpenses();
  };
  
  // Calculate total annual expenses
  const getTotalAnnualExpenses = () => {
    // Get annual expenses directly
    const annualExpenses = getAnnualExpenses().reduce((sum, expense) => sum + expense.amount, 0);
    
    // Get regular expenses multiplied by 12
    const regularExpensesAnnualized = getRegularMonthlyExpenses() * 12;
    
    return annualExpenses + regularExpensesAnnualized;
  };
  
  // Calculate annual balance
  const getAnnualBalance = () => {
    // Annual balance = annual income - annual expenses
    return getTotalAnnualIncome() - getTotalAnnualExpenses();
  };

  // Get expense percentage of income
  const getExpensePercentage = () => {
    const monthlyIncome = getTotalMonthlyIncome();
    if (monthlyIncome === 0) return 0;
    
    // Calculate percentage of income
    return (getTotalMonthlyExpenses() / monthlyIncome) * 100;
  };

  // Get budget status based on expense percentage
  const getBudgetStatus = (): 'good' | 'warning' | 'danger' => {
    const percentage = getExpensePercentage();
    
    if (percentage < 70) return 'good';
    if (percentage < 90) return 'warning';
    return 'danger';
  };

  return (
    <ExpenseContext.Provider value={{ 
      // Expenses
      expenses,
      addExpense,
      updateExpense,
      deleteExpense,
      toggleExpenseStatus,
      toggleExpenseRepeatable,
      getCurrentMonthExpenses,
      getAnnualExpenses,
      getExpensesByFamilyMember,
      getMonthlyEquivalentOfAnnualExpenses,
      getTotalMonthlyExpenses,
      getTotalMonthlyExpensesByMember,
      getRegularMonthlyExpenses,
      getProratedAnnualExpenses,
      
      // Categories
      categories,
      addCategory,
      updateCategory,
      deleteCategory,
      
      // Income
      incomeSources,
      addIncomeSource,
      updateIncomeSource,
      deleteIncomeSource,
      getTotalMonthlyIncome,
      getTotalAnnualIncome,
      
      // Family Members
      familyMembers,
      addFamilyMember,
      updateFamilyMember,
      deleteFamilyMember,
      
      // Budget & Financial Insights
      getMonthlyBalance,
      getBudgetStatus,
      getExpensePercentage,
      getTotalAnnualExpenses,
      getAnnualBalance,
      
      // Settings
      currency,
      setCurrency: setCurrencyAndSave, // Use the Firestore-enabled function
      clearData
    }}>
      {children}
    </ExpenseContext.Provider>
  );
};

// Custom hook to use the expense context
export const useExpenses = () => {
  const context = useContext(ExpenseContext);
  if (context === undefined) {
    throw new Error('useExpenses must be used within a ExpenseProvider');
  }
  return context;
};