import React, { useState } from 'react';
import { 
  TextField, 
  Button, 
  Grid, 
  MenuItem, 
  FormControl, 
  InputLabel, 
  Select, 
  Box, 
  Paper, 
  Typography,
  Alert,
  Snackbar,
  InputAdornment,
  FormControlLabel,
  Switch,
  Tab,
  Tabs
} from '@mui/material';
import { CalendarMonth, CalendarToday } from '@mui/icons-material';
import { useExpenses } from '../../contexts/ExpenseContext';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`expense-tabpanel-${index}`}
      aria-labelledby={`expense-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

const ExpenseForm: React.FC = () => {
  const { addExpense, categories, familyMembers, currency } = useExpenses();
  const [tabValue, setTabValue] = useState(0);
  
  // Common fields
  const [description, setDescription] = useState('');
  const [amount, setAmount] = useState('');
  const [date, setDate] = useState(new Date().toISOString().split('T')[0]);
  const [category, setCategory] = useState('');
  const [assignedTo, setAssignedTo] = useState('1'); // Default to "Self"
  const [isEnabled, setIsEnabled] = useState(true);
  const [repeatable, setRepeatable] = useState(false); // Repeatable expense toggle
  
  // Annual expense fields
  const [isSpreadAcrossMonths, setIsSpreadAcrossMonths] = useState(true);
  
  const [alert, setAlert] = useState({ open: false, message: '', severity: 'success' as 'success' | 'error' });

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleSubmit = (e: React.FormEvent, type: 'regular' | 'annual') => {
    e.preventDefault();
    
    // Simple validation
    if (!description || !amount || !date || !category) {
      setAlert({
        open: true,
        message: 'Please fill in all required fields',
        severity: 'error'
      });
      return;
    }

    // Add the expense
    addExpense({
      description,
      amount: parseFloat(amount),
      date: new Date(date),
      category,
      assignedTo,
      isEnabled,
      type,
      repeatable: type === 'regular' ? repeatable : false, // Only regular expenses can be repeatable
      ...(type === 'annual' && { isSpreadAcrossMonths }),
    });

    // Reset form
    setDescription('');
    setAmount('');
    setDate(new Date().toISOString().split('T')[0]);
    setCategory('');
    setAssignedTo('1');
    setIsEnabled(true);
    setRepeatable(false);
    setIsSpreadAcrossMonths(true);

    // Show success message
    setAlert({
      open: true,
      message: `${type === 'annual' ? 'Annual' : 'Regular'} expense added successfully!`,
      severity: 'success'
    });
  };

  const handleCloseAlert = () => {
    setAlert({ ...alert, open: false });
  };

  return (
    <Paper sx={{ p: 2 }}>
      <Typography variant="h6" gutterBottom>
        Add New Expense
      </Typography>

      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs 
          value={tabValue} 
          onChange={handleTabChange} 
          aria-label="expense type tabs"
          variant="fullWidth"
        >
          <Tab 
            label="Regular Expense" 
            icon={<CalendarToday />} 
            iconPosition="start" 
          />
          <Tab 
            label="Annual Expense" 
            icon={<CalendarMonth />} 
            iconPosition="start" 
          />
        </Tabs>
      </Box>

      {/* Regular Expense Form */}
      <TabPanel value={tabValue} index={0}>
        <Box 
          component="form" 
          onSubmit={(e) => handleSubmit(e, 'regular')} 
          sx={{ mt: 2 }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="description"
                name="description"
                label="Description"
                fullWidth
                variant="outlined"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Grid>
            
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="amount"
                name="amount"
                label={`Amount (${currency.symbol})`}
                type="number"
                fullWidth
                variant="outlined"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                inputProps={{ step: "0.01", min: "0" }}
                InputProps={{
                  startAdornment: <InputAdornment position="start">{currency.symbol}</InputAdornment>,
                }}
              />
            </Grid>
            
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="date"
                name="date"
                label="Date"
                type="date"
                fullWidth
                variant="outlined"
                value={date}
                onChange={(e) => setDate(e.target.value)}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth required>
                <InputLabel id="category-label">Category</InputLabel>
                <Select
                  labelId="category-label"
                  id="category"
                  value={category}
                  label="Category"
                  onChange={(e) => setCategory(e.target.value)}
                >
                  {categories.map((cat) => (
                    <MenuItem key={cat} value={cat}>
                      {cat}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel id="assigned-to-label">Assigned To</InputLabel>
                <Select
                  labelId="assigned-to-label"
                  id="assigned-to"
                  value={assignedTo}
                  label="Assigned To"
                  onChange={(e) => setAssignedTo(e.target.value)}
                >
                  {familyMembers.map((member) => (
                    <MenuItem key={member.id} value={member.id}>
                      {member.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            
            <Grid item xs={12} sm={6}>
              <Box>
                <FormControlLabel
                  control={
                    <Switch
                      checked={isEnabled}
                      onChange={(e) => setIsEnabled(e.target.checked)}
                      color="primary"
                    />
                  }
                  label="Include in budget calculations"
                />
                
                <FormControlLabel
                  control={
                    <Switch
                      checked={repeatable}
                      onChange={(e) => setRepeatable(e.target.checked)}
                      color="secondary"
                    />
                  }
                  label="Repeatable expense"
                />
                
                {repeatable && (
                  <Typography variant="caption" color="text.secondary" display="block">
                    This expense will be included in every month starting from the date.
                  </Typography>
                )}
              </Box>
            </Grid>
            
            <Grid item xs={12}>
              <Button 
                type="submit" 
                variant="contained" 
                color="primary" 
                fullWidth
                size="large"
              >
                Add Regular Expense
              </Button>
            </Grid>
          </Grid>
        </Box>
      </TabPanel>

      {/* Annual Expense Form */}
      <TabPanel value={tabValue} index={1}>
        <Box 
          component="form" 
          onSubmit={(e) => handleSubmit(e, 'annual')} 
          sx={{ mt: 2 }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="annual-description"
                name="annual-description"
                label="Description"
                fullWidth
                variant="outlined"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="e.g., Home Insurance, Property Tax"
              />
            </Grid>
            
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="annual-amount"
                name="annual-amount"
                label={`Annual Amount (${currency.symbol})`}
                type="number"
                fullWidth
                variant="outlined"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                inputProps={{ step: "0.01", min: "0" }}
                InputProps={{
                  startAdornment: <InputAdornment position="start">{currency.symbol}</InputAdornment>,
                }}
              />
            </Grid>
            
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="annual-date"
                name="annual-date"
                label="Due Date"
                type="date"
                fullWidth
                variant="outlined"
                value={date}
                onChange={(e) => setDate(e.target.value)}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth required>
                <InputLabel id="annual-category-label">Category</InputLabel>
                <Select
                  labelId="annual-category-label"
                  id="annual-category"
                  value={category}
                  label="Category"
                  onChange={(e) => setCategory(e.target.value)}
                >
                  {categories.map((cat) => (
                    <MenuItem key={cat} value={cat}>
                      {cat}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel id="annual-assigned-to-label">Assigned To</InputLabel>
                <Select
                  labelId="annual-assigned-to-label"
                  id="annual-assigned-to"
                  value={assignedTo}
                  label="Assigned To"
                  onChange={(e) => setAssignedTo(e.target.value)}
                >
                  {familyMembers.map((member) => (
                    <MenuItem key={member.id} value={member.id}>
                      {member.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            
            <Grid item xs={12} sm={6}>
              <Box>
                <FormControlLabel
                  control={
                    <Switch
                      checked={isEnabled}
                      onChange={(e) => setIsEnabled(e.target.checked)}
                      color="primary"
                    />
                  }
                  label="Include in budget calculations"
                />
                
                <FormControlLabel
                  control={
                    <Switch
                      checked={isSpreadAcrossMonths}
                      onChange={(e) => setIsSpreadAcrossMonths(e.target.checked)}
                      color="secondary"
                    />
                  }
                  label="Spread across monthly budget"
                />
                
                {isSpreadAcrossMonths && (
                  <Typography variant="caption" color="text.secondary" display="block">
                    This expense will be divided by 12 and included in your monthly budget calculations.
                  </Typography>
                )}
              </Box>
            </Grid>
            
            <Grid item xs={12}>
              <Button 
                type="submit" 
                variant="contained" 
                color="primary" 
                fullWidth
                size="large"
              >
                Add Annual Expense
              </Button>
            </Grid>
          </Grid>
        </Box>
      </TabPanel>

      <Snackbar 
        open={alert.open} 
        autoHideDuration={6000} 
        onClose={handleCloseAlert}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseAlert} severity={alert.severity}>
          {alert.message}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default ExpenseForm;