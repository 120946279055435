import React, { useState } from 'react';
import {
  Paper,
  Typography,
  Box,
  Button,
  TextField,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Avatar,
  ListItemAvatar,
  Chip
} from '@mui/material';
import { Add, Edit, Delete, Person, People } from '@mui/icons-material';
import { useExpenses, FamilyMember } from '../../contexts/ExpenseContext';
import { blue, green, purple, orange, pink } from '@mui/material/colors';

// Generate a color based on the name
const generateColor = (name: string) => {
  const colors = [blue[500], green[500], purple[500], orange[500], pink[500]];
  const charCode = name.charCodeAt(0);
  return colors[charCode % colors.length];
};

const FamilyManager: React.FC = () => {
  const {
    familyMembers,
    addFamilyMember,
    updateFamilyMember,
    deleteFamilyMember,
    getExpensesByFamilyMember,
    getTotalMonthlyExpensesByMember,
    currency
  } = useExpenses();

  const [open, setOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [currentMemberId, setCurrentMemberId] = useState('');
  const [name, setName] = useState('');

  const handleClickOpen = (isEdit: boolean = false, member?: FamilyMember) => {
    setEditMode(isEdit);
    if (isEdit && member) {
      setCurrentMemberId(member.id);
      setName(member.name);
    } else {
      setCurrentMemberId('');
      setName('');
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    if (editMode) {
      updateFamilyMember(currentMemberId, { name });
    } else {
      addFamilyMember({ name });
    }
    handleClose();
  };

  // Use the consistent calculation from the context
  const getMemberExpenseTotal = (memberId: string) => {
    // Get total monthly expenses for this family member
    return getTotalMonthlyExpensesByMember(memberId);
  };

  // Check if a family member can be deleted (not "Self" and has no expenses)
  const canDeleteMember = (memberId: string) => {
    return memberId !== '1'; // 'Self' should not be deletable
  };

  return (
    <Paper sx={{ p: 3, mb: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h6">Family Members</Typography>
        <Button
          variant="contained"
          startIcon={<Add />}
          onClick={() => handleClickOpen(false)}
        >
          Add Family Member
        </Button>
      </Box>

      <Divider sx={{ mb: 3 }} />

      {/* Family Members List */}
      <List>
        {familyMembers.map((member) => {
          const totalExpenses = getMemberExpenseTotal(member.id);
          const expenseCount = getExpensesByFamilyMember(member.id).length;
          const isDefault = member.id === '1'; // Check if it's the "Self" member
          
          return (
            <React.Fragment key={member.id}>
              <ListItem
                secondaryAction={
                  !isDefault && (
                    <Box>
                      <IconButton
                        edge="end"
                        aria-label="edit"
                        onClick={() => handleClickOpen(true, member)}
                      >
                        <Edit />
                      </IconButton>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        sx={{ ml: 1 }}
                        disabled={!canDeleteMember(member.id)}
                        onClick={() => canDeleteMember(member.id) && deleteFamilyMember(member.id)}
                      >
                        <Delete />
                      </IconButton>
                    </Box>
                  )
                }
              >
                <ListItemAvatar>
                  <Avatar sx={{ bgcolor: generateColor(member.name) }}>
                    {member.name.charAt(0).toUpperCase()}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={<Typography variant="subtitle1">{member.name}</Typography>}
                  secondary={
                    <Box sx={{ display: 'flex', flexDirection: 'column', mt: 0.5 }}>
                      <Typography variant="body2" color="text.secondary">
                        Total Expenses: {currency.symbol}{totalExpenses.toFixed(2)}
                      </Typography>
                      <Box sx={{ mt: 0.5 }}>
                        {isDefault && <Chip label="Default" size="small" color="primary" sx={{ mr: 1 }} />}
                        <Chip
                          label={`${expenseCount} expense${expenseCount !== 1 ? 's' : ''}`}
                          size="small"
                          variant="outlined"
                        />
                      </Box>
                    </Box>
                  }
                />
              </ListItem>
              <Divider variant="inset" component="li" />
            </React.Fragment>
          );
        })}
      </List>

      {/* Add/Edit Family Member Dialog */}
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>
          {editMode ? 'Edit Family Member' : 'Add Family Member'}
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            type="text"
            fullWidth
            variant="outlined"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="e.g., John, Alice, Kids"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave} variant="contained" disabled={!name.trim()}>
            {editMode ? 'Update' : 'Add'}
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default FamilyManager;