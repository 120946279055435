import React, { useState } from 'react';
import {
  Paper,
  Typography,
  Box,
  Button,
  TextField,
  IconButton,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Divider,
  InputAdornment,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Switch,
  Chip,
} from '@mui/material';
import { Add, Edit, Delete, CalendarMonth } from '@mui/icons-material';
import { useExpenses, Expense } from '../../contexts/ExpenseContext';

const AnnualExpenseManager: React.FC = () => {
  const {
    expenses,
    addExpense,
    updateExpense,
    deleteExpense,
    getAnnualExpenses,
    getMonthlyEquivalentOfAnnualExpenses,
    categories,
    familyMembers,
    currency,
  } = useExpenses();

  const [open, setOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [currentExpenseId, setCurrentExpenseId] = useState('');
  const [description, setDescription] = useState('');
  const [amount, setAmount] = useState('');
  const [date, setDate] = useState(new Date().toISOString().split('T')[0]);
  const [category, setCategory] = useState('');
  const [assignedTo, setAssignedTo] = useState('1'); // Default to "Self"
  const [isSpreadAcrossMonths, setIsSpreadAcrossMonths] = useState(true);

  // Get all annual expenses
  const annualExpenses = getAnnualExpenses();
  const monthlyEquivalent = getMonthlyEquivalentOfAnnualExpenses();

  const handleClickOpen = (isEdit: boolean = false, expense?: Expense) => {
    setEditMode(isEdit);
    if (isEdit && expense) {
      setCurrentExpenseId(expense.id);
      setDescription(expense.description);
      setAmount(expense.amount.toString());
      setDate(new Date(expense.date).toISOString().split('T')[0]);
      setCategory(expense.category);
      setAssignedTo(expense.assignedTo || '1');
      setIsSpreadAcrossMonths(expense.isSpreadAcrossMonths || false);
    } else {
      setCurrentExpenseId('');
      setDescription('');
      setAmount('');
      setDate(new Date().toISOString().split('T')[0]);
      setCategory('');
      setAssignedTo('1');
      setIsSpreadAcrossMonths(true);
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    const expenseData: Omit<Expense, 'id'> = {
      description,
      amount: parseFloat(amount),
      date: new Date(date),
      category,
      assignedTo,
      isEnabled: true,
      type: 'annual',
      isSpreadAcrossMonths,
    };

    if (editMode) {
      updateExpense(currentExpenseId, expenseData);
    } else {
      addExpense(expenseData);
    }

    handleClose();
  };

  const handleDelete = (id: string) => {
    deleteExpense(id);
  };

  const handleToggleSpread = (id: string, currentValue: boolean) => {
    updateExpense(id, { isSpreadAcrossMonths: !currentValue });
  };

  // Format date for display
  const formatDate = (date: Date) => {
    return new Date(date).toLocaleDateString();
  };

  return (
    <Paper sx={{ p: 3, mb: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h6">Annual Expenses</Typography>
        <Button
          variant="contained"
          startIcon={<Add />}
          onClick={() => handleClickOpen(false)}
        >
          Add Annual Expense
        </Button>
      </Box>

      <Divider sx={{ mb: 3 }} />

      {/* Annual Expenses Summary */}
      <Grid container spacing={2} sx={{ mb: 3 }}>
        <Grid item xs={12} sm={6}>
          <Box sx={{ p: 2, border: '1px solid #e0e0e0', borderRadius: 1 }}>
            <Typography variant="subtitle2" color="text.secondary">
              Total Annual Expenses
            </Typography>
            <Typography variant="h5" color="primary">
              {currency.symbol}
              {annualExpenses
                .reduce((sum, expense) => sum + expense.amount, 0)
                .toFixed(2)}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box sx={{ p: 2, border: '1px solid #e0e0e0', borderRadius: 1 }}>
            <Typography variant="subtitle2" color="text.secondary">
              Monthly Equivalent (Spread)
            </Typography>
            <Typography variant="h5" color="primary">
              {currency.symbol}{monthlyEquivalent.toFixed(2)}
            </Typography>
            <Typography variant="caption" color="text.secondary">
              Only includes expenses marked as "spread across months"
            </Typography>
          </Box>
        </Grid>
      </Grid>

      {/* Annual Expenses List */}
      {annualExpenses.length > 0 ? (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Description</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Due Date</TableCell>
                <TableCell>Category</TableCell>
                <TableCell>Assigned To</TableCell>
                <TableCell>Monthly Budget</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {annualExpenses.map((expense) => (
                <TableRow key={expense.id}>
                  <TableCell>{expense.description}</TableCell>
                  <TableCell>
                    {currency.symbol}{expense.amount.toFixed(2)}
                  </TableCell>
                  <TableCell>{formatDate(expense.date)}</TableCell>
                  <TableCell>
                    <Chip label={expense.category} size="small" />
                  </TableCell>
                  <TableCell>
                    {familyMembers.find(m => m.id === expense.assignedTo)?.name || 'Self'}
                  </TableCell>
                  <TableCell>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={expense.isSpreadAcrossMonths}
                          onChange={() => handleToggleSpread(expense.id, expense.isSpreadAcrossMonths || false)}
                          color="primary"
                          size="small"
                        />
                      }
                      label={expense.isSpreadAcrossMonths ? 'Included' : 'Excluded'}
                    />
                  </TableCell>
                  <TableCell align="right">
                    <IconButton
                      size="small"
                      onClick={() => handleClickOpen(true, expense)}
                    >
                      <Edit fontSize="small" />
                    </IconButton>
                    <IconButton
                      size="small"
                      color="error"
                      onClick={() => handleDelete(expense.id)}
                    >
                      <Delete fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Box sx={{ textAlign: 'center', py: 3 }}>
          <Typography variant="body1" color="text.secondary">
            No annual expenses added yet.
          </Typography>
          <Button
            variant="outlined"
            startIcon={<Add />}
            onClick={() => handleClickOpen(false)}
            sx={{ mt: 2 }}
          >
            Add Your First Annual Expense
          </Button>
        </Box>
      )}

      {/* Add/Edit Annual Expense Dialog */}
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>
          {editMode ? 'Edit Annual Expense' : 'Add Annual Expense'}
        </DialogTitle>
        <DialogContent>
          <Box component="form" sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              label="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              autoFocus
              placeholder="e.g., Home Insurance, Property Tax"
            />
            <TextField
              margin="normal"
              required
              fullWidth
              label="Amount"
              type="number"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">{currency.symbol}</InputAdornment>
                ),
              }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              label="Due Date"
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              InputLabelProps={{ shrink: true }}
            />
            <FormControl fullWidth margin="normal">
              <InputLabel id="category-label">Category</InputLabel>
              <Select
                labelId="category-label"
                value={category}
                label="Category"
                onChange={(e) => setCategory(e.target.value)}
              >
                {categories.map((cat) => (
                  <MenuItem key={cat} value={cat}>
                    {cat}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth margin="normal">
              <InputLabel id="assigned-to-label">Assigned To</InputLabel>
              <Select
                labelId="assigned-to-label"
                value={assignedTo}
                label="Assigned To"
                onChange={(e) => setAssignedTo(e.target.value)}
              >
                {familyMembers.map((member) => (
                  <MenuItem key={member.id} value={member.id}>
                    {member.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControlLabel
              control={
                <Switch
                  checked={isSpreadAcrossMonths}
                  onChange={(e) => setIsSpreadAcrossMonths(e.target.checked)}
                  color="primary"
                />
              }
              label="Spread across monthly budget"
              sx={{ mt: 2 }}
            />
            {isSpreadAcrossMonths && (
              <Typography variant="caption" color="text.secondary" display="block" sx={{ mt: 1 }}>
                This expense will be divided by 12 and included in your monthly budget calculations.
              </Typography>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={handleSave}
            variant="contained"
            disabled={!description || !amount || parseFloat(amount) <= 0 || !category || !date}
          >
            {editMode ? 'Update' : 'Add'}
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default AnnualExpenseManager;