import React, { Suspense } from 'react';
import { ThemeProvider, createTheme, CssBaseline, CircularProgress, Box } from '@mui/material';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AuthProvider } from './contexts/AuthContext';
import { ExpenseProvider } from './contexts/ExpenseContext';
import { LanguageProvider } from './contexts/LanguageContext';
import Dashboard from './components/dashboard/Dashboard';
import Login from './components/auth/Login';
import Register from './components/auth/Register';
import Profile from './components/auth/Profile';
import ProtectedRoute from './components/auth/ProtectedRoute';
import Layout from './components/layout/Layout';
import './App.css';

// Import i18n configuration
import './i18n/i18n';

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: '#dc004e',
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          width: '100%',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none', // Better for internationalization
        },
      },
    },
  },
  typography: {
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    fontSize: 14,
    h4: {
      fontSize: '1.75rem',
      '@media (max-width:600px)': {
        fontSize: '1.5rem',
      },
    },
    h6: {
      fontSize: '1.25rem',
      '@media (max-width:600px)': {
        fontSize: '1.1rem',
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});

// Loading component for suspense fallback
const Loader = () => (
  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
    <CircularProgress />
  </Box>
);

function App() {
  const { i18n } = useTranslation();
  const isRtl = i18n.language === 'ar'; // Arabic is right-to-left
  
  // Create a responsive theme that changes direction for RTL languages
  const responsiveTheme = React.useMemo(
    () =>
      createTheme({
        ...theme,
        direction: isRtl ? 'rtl' : 'ltr',
      }),
    [isRtl]
  );

  return (
    <Suspense fallback={<Loader />}>
      <ThemeProvider theme={responsiveTheme}>
        <CssBaseline />
        <Box sx={{ 
          direction: isRtl ? 'rtl' : 'ltr', 
          textAlign: isRtl ? 'right' : 'left',
          fontFamily: isRtl ? 'Tahoma, Arial, sans-serif' : 'inherit'
        }}>
          <AuthProvider>
            <LanguageProvider>
              <ExpenseProvider>
                <Router>
                  <Routes>
                    {/* Auth Routes */}
                    <Route path="/login" element={<Login />} />
                    <Route path="/register" element={<Register />} />
                    
                    {/* Protected Routes */}
                    <Route element={<ProtectedRoute />}>
                      <Route element={<Layout />}>
                        <Route path="/" element={<Dashboard />} />
                        <Route path="/profile" element={<Profile />} />
                      </Route>
                    </Route>
                    
                    {/* Redirect any unknown routes to home */}
                    <Route path="*" element={<Navigate to="/" />} />
                  </Routes>
                </Router>
              </ExpenseProvider>
            </LanguageProvider>
          </AuthProvider>
        </Box>
      </ThemeProvider>
    </Suspense>
  );
}

export default App;
