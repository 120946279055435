import React, { useState } from 'react';
import { 
  AppBar, 
  Toolbar, 
  Typography, 
  Button, 
  Box, 
  Avatar, 
  Menu, 
  MenuItem,
  ListItemIcon,
  Divider,
  Select,
  FormControl,
  InputLabel,
  SelectChangeEvent
} from '@mui/material';
import { 
  Logout, 
  AccountCircle, 
  CurrencyExchange, 
  Translate as TranslateIcon 
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../contexts/AuthContext';
import { useExpenses, currencies } from '../../contexts/ExpenseContext';
import { useLanguage } from '../../contexts/LanguageContext';

const Header: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { t } = useTranslation();
  const { currentUser, logout } = useAuth();
  const { currency, setCurrency } = useExpenses();
  const { language, setLanguage, availableLanguages } = useLanguage();
  const navigate = useNavigate();
  
  const open = Boolean(anchorEl);
  
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      console.error('Failed to log out:', error);
    }
  };
  
  const handleCurrencyChange = (event: SelectChangeEvent) => {
    const selectedCurrency = currencies.find(c => c.code === event.target.value);
    if (selectedCurrency) {
      setCurrency(selectedCurrency);
    }
  };
  
  const handleLanguageChange = (event: SelectChangeEvent) => {
    const selectedLanguage = availableLanguages.find(l => l.code === event.target.value);
    if (selectedLanguage) {
      setLanguage(selectedLanguage);
    }
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          {t('header.home')}
        </Typography>
        
        {currentUser && (
          <>
            {/* Currency Selection */}
            <FormControl 
              variant="outlined" 
              size="small" 
              sx={{ 
                minWidth: 120, 
                mr: 2,
                backgroundColor: 'rgba(255, 255, 255, 0.15)', 
                borderRadius: 1,
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'transparent',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'transparent',
                },
                '& .MuiInputLabel-root': {
                  color: 'white',
                },
                '& .MuiSelect-select': {
                  color: 'white',
                }
              }}
            >
              <InputLabel id="currency-select-label">{t('header.currency')}</InputLabel>
              <Select
                labelId="currency-select-label"
                id="currency-select"
                value={currency.code}
                label={t('header.currency')}
                onChange={handleCurrencyChange}
              >
                {currencies.map((curr) => (
                  <MenuItem key={curr.code} value={curr.code}>
                    {curr.symbol} {t(`currencies.${curr.code}`)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            
            {/* Language Selection */}
            <FormControl 
              variant="outlined" 
              size="small" 
              sx={{ 
                minWidth: 120, 
                mr: 2,
                backgroundColor: 'rgba(255, 255, 255, 0.15)', 
                borderRadius: 1,
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'transparent',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'transparent',
                },
                '& .MuiInputLabel-root': {
                  color: 'white',
                },
                '& .MuiSelect-select': {
                  color: 'white',
                }
              }}
            >
              <InputLabel id="language-select-label">{t('header.language')}</InputLabel>
              <Select
                labelId="language-select-label"
                id="language-select"
                value={language.code}
                label={t('header.language')}
                onChange={handleLanguageChange}
              >
                {availableLanguages.map((lang) => (
                  <MenuItem key={lang.code} value={lang.code}>
                    {t(`settings.${lang.code === 'en' ? 'english' : lang.code === 'ar' ? 'arabic' : lang.code === 'fr' ? 'french' : 'german'}`)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Button
                color="inherit"
                onClick={handleClick}
                startIcon={
                  <Avatar 
                    sx={{ width: 32, height: 32, bgcolor: 'primary.dark' }}
                  >
                    {currentUser.displayName ? currentUser.displayName.charAt(0).toUpperCase() : 'U'}
                  </Avatar>
                }
              >
                {currentUser.displayName || currentUser.email}
              </Button>
            </Box>
            
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              <MenuItem onClick={() => navigate('/profile')}>
                <ListItemIcon>
                  <AccountCircle fontSize="small" />
                </ListItemIcon>
                {t('header.profile')}
              </MenuItem>
              <MenuItem>
                <ListItemIcon>
                  <CurrencyExchange fontSize="small" />
                </ListItemIcon>
                {t('header.currency')}: {currency.code}
              </MenuItem>
              <MenuItem>
                <ListItemIcon>
                  <TranslateIcon fontSize="small" />
                </ListItemIcon>
                {t('header.language')}: {language.name}
              </MenuItem>
              <Divider />
              <MenuItem onClick={handleLogout}>
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                {t('auth.logout')}
              </MenuItem>
            </Menu>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;