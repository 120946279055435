// Import the required functions from Firebase SDK
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { 
  getAuth, 
  createUserWithEmailAndPassword, 
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  updateProfile,
  GoogleAuthProvider,
  signInWithPopup,
  linkWithPopup,
  fetchSignInMethodsForEmail,
  getAdditionalUserInfo,
  EmailAuthProvider,
  signInWithCredential
} from 'firebase/auth';
import {
  getFirestore,
  collection,
  doc,
  addDoc,
  setDoc,
  updateDoc,
  deleteDoc,
  getDocs,
  query,
  where,
  Timestamp,
  writeBatch
} from 'firebase/firestore';

// ✅ Correct Firebase Configuration
const firebaseConfig = {
  apiKey: "AIzaSyDq3nSVJn323YLLqWyLTaQLeR7AIxhofcc",
  authDomain: "personal-financial-app-f0036.firebaseapp.com",
  projectId: "personal-financial-app-f0036",
  storageBucket: "personal-financial-app-f0036.appspot.com",
  messagingSenderId: "121000656459",
  appId: "1:121000656459:web:b7208bc8e10d1e226897ce",
  measurementId: "G-97S3CXP9BM"
};

// 🔥 Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app);

// Firestore collection references
const expensesCollection = collection(db, 'expenses');
const incomeCollection = collection(db, 'income');
const familyMembersCollection = collection(db, 'familyMembers');
const categoriesCollection = collection(db, 'categories');
const userSettingsCollection = collection(db, 'userSettings');

// Helper function to convert Firestore timestamps to JavaScript Date objects
export const convertTimestampToDate = (data: any) => {
  // If this is a Firestore timestamp, convert to JavaScript Date
  if (data instanceof Timestamp) {
    return data.toDate();
  }
  
  // If this is an object that might contain timestamps, process it recursively
  if (data && typeof data === 'object') {
    Object.keys(data).forEach(key => {
      data[key] = convertTimestampToDate(data[key]);
    });
  }
  
  return data;
};

// ✅ Export authentication & Firestore functions
export { 
  auth,
  db,
  expensesCollection,
  incomeCollection,
  familyMembersCollection,
  categoriesCollection,
  userSettingsCollection,
  // Auth exports
  createUserWithEmailAndPassword, 
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  updateProfile,
  GoogleAuthProvider,
  signInWithPopup,
  linkWithPopup,
  fetchSignInMethodsForEmail,
  getAdditionalUserInfo,
  EmailAuthProvider,
  signInWithCredential,
  // Firestore exports 
  collection,
  doc,
  addDoc,
  setDoc,
  updateDoc,
  deleteDoc,
  getDocs,
  query,
  where,
  Timestamp,
  writeBatch
};
