import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { 
  auth, 
  createUserWithEmailAndPassword, 
  signInWithEmailAndPassword, 
  signOut,
  updateProfile,
  onAuthStateChanged,
  GoogleAuthProvider,
  signInWithPopup,
  linkWithPopup,
  fetchSignInMethodsForEmail,
  getAdditionalUserInfo,
  EmailAuthProvider,
  signInWithCredential
} from '../firebase';
import { User, AuthError, AuthCredential } from 'firebase/auth';
import { setPersistence, browserLocalPersistence } from 'firebase/auth';

// Force Firebase authentication to persist across sessions (prevents mobile login issues)
setPersistence(auth, browserLocalPersistence)
  .then(() => console.log("✅ Firebase Auth persistence set to localStorage"))
  .catch((error) => console.error("❌ Error setting persistence:", error));

interface AuthContextType {
  currentUser: User | null;
  loading: boolean;
  register: (name: string, email: string, password: string) => Promise<void>;
  login: (email: string, password: string) => Promise<void>;
  loginWithGoogle: () => Promise<{ user: User; isNewUser: boolean; linkedAccount: boolean }>;
  linkWithGoogle: () => Promise<boolean>;
  checkExistingAccount: (email: string) => Promise<string[]>;
  logout: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export const AuthProvider: React.FC<{children: ReactNode}> = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  
  // Google provider
  const googleProvider = new GoogleAuthProvider();

  // Register a new user
  const register = async (name: string, email: string, password: string) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      // Update profile with display name
      if (userCredential.user) {
        await updateProfile(userCredential.user, {
          displayName: name
        });
      }
    } catch (error) {
      console.error('Registration error:', error);
      throw error;
    }
  };

  // Login existing user
  const login = async (email: string, password: string) => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
      console.error('Login error:', error);
      throw error;
    }
  };
  
  // Check if an email already exists in Firebase
  const checkExistingAccount = async (email: string): Promise<string[]> => {
    try {
      return await fetchSignInMethodsForEmail(auth, email);
    } catch (error) {
      console.error('Error checking existing account:', error);
      return [];
    }
  };

  // Login with Google with improved handling for existing accounts
  const loginWithGoogle = async (): Promise<{ user: User; isNewUser: boolean; linkedAccount: boolean }> => {
    try {
      let linkedAccount = false;
      
      try {
        const result = await signInWithPopup(auth, googleProvider);
        // Get additional user info to check if new user
        const additionalInfo = getAdditionalUserInfo(result);
        const isNewUser = additionalInfo?.isNewUser || false;
        
        return { 
          user: result.user, 
          isNewUser, 
          linkedAccount 
        };
      } catch (error) {
        const authError = error as AuthError;
        
        // If the error is because the account exists with different credentials
        if (authError.code === 'auth/account-exists-with-different-credential') {
          // Get the email from the error
          const email = authError.customData?.email as string;
          
          if (email) {
            // Get sign-in methods for the email
            const methods = await fetchSignInMethodsForEmail(auth, email);
            
            // If the email has password sign-in method, we can try to link accounts
            if (methods.includes('password')) {
              // We need to ask the user for their password to sign in and link
              // For this demo, we'll auto-link by signing in with the email provider
              // In a real app, you would prompt the user for their password
              
              // Get Google Auth credential from the error
              const credential = GoogleAuthProvider.credentialFromError(authError);
              
              if (credential) {
                // Sign in with the original email/password
                // This is simplified - in a real app you'd prompt the user
                // Here we're demonstrating the concept with a simulated sign-in
                try {
                  // Sign in with the existing account
                  const userCredential = await signInWithEmailAndPassword(
                    auth, 
                    email, 
                    'password' // Placeholder - would be user input in real app
                  );
                  
                  // Link the Google credential to the existing account
                  await linkWithPopup(userCredential.user, googleProvider);
                  
                  linkedAccount = true;
                  return { 
                    user: userCredential.user, 
                    isNewUser: false, 
                    linkedAccount 
                  };
                } catch (linkError) {
                  console.error('Error during auto-linking:', linkError);
                  throw error; // Re-throw the original error if linking fails
                }
              }
            }
          }
        }
        
        // If we couldn't handle the error, re-throw it
        throw error;
      }
    } catch (error) {
      console.error('Google login error:', error);
      throw error;
    }
  };
  
  // Link current account with Google - improved with better error handling
  const linkWithGoogle = async (): Promise<boolean> => {
    if (!currentUser) {
      throw new Error('No user is currently logged in');
    }
    
    try {
      // Check if already linked with Google
      const hasGoogleProvider = currentUser.providerData.some(
        provider => provider.providerId === 'google.com'
      );
      
      if (hasGoogleProvider) {
        console.log('Account already linked with Google');
        return true;
      }
      
      // Try to link with Google
      const result = await linkWithPopup(currentUser, googleProvider);
      console.log('Account successfully linked with Google');
      return true;
    } catch (error) {
      const authError = error as AuthError;
      
      // Handle specific error cases
      if (authError.code === 'auth/credential-already-in-use') {
        console.error('This Google account is already linked to another user');
        
        // You could potentially sign in with this credential and migrate data
        // between accounts, but that's beyond this example's scope
        
        // Get the credential from the error
        const credential = GoogleAuthProvider.credentialFromError(authError);
        if (credential && authError.customData?.email) {
          console.log(`The Google account (${authError.customData.email}) is already in use.`);
        }
      } else if (authError.code === 'auth/email-already-in-use') {
        console.error('An account with this email already exists');
      } else if (authError.code === 'auth/requires-recent-login') {
        console.error('Please sign in again before linking your Google account');
      }
      
      console.error('Error linking with Google:', error);
      throw error;
    }
  };

  // Logout current user
  const logout = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error('Logout error:', error);
      throw error;
    }
  };

  // Listen for authentication state changes
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    loading,
    register,
    login,
    loginWithGoogle,
    linkWithGoogle,
    checkExistingAccount,
    logout
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};