import React, { useState } from 'react';
import { 
  TextField, 
  Button, 
  Box, 
  Typography, 
  Container, 
  Paper,
  Alert,
  Link,
  Divider,
  CircularProgress
} from '@mui/material';
import { Google as GoogleIcon } from '@mui/icons-material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { useTranslation } from 'react-i18next';

const Register: React.FC = () => {
  const { t } = useTranslation();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [googleLoading, setGoogleLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  
  const { register, loginWithGoogle } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    // Reset states
    setError('');
    setSuccess(false);
    
    // Validation
    if (password !== confirmPassword) {
      return setError(t('auth.passwordMismatch') || 'Passwords do not match');
    }
    
    if (password.length < 6) {
      return setError(t('auth.passwordTooShort') || 'Password must be at least 6 characters');
    }
    
    try {
      setLoading(true);
      await register(name, email, password);
      setSuccess(true);
    } catch (err: any) {
      setError(err.message || 'Failed to create an account');
    } finally {
      setLoading(false);
    }
  };
  
  const handleGoogleRegister = async () => {
    setError('');
    setGoogleLoading(true);
    
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { user, isNewUser, linkedAccount } = await loginWithGoogle();
      
      // If we linked an account during registration, show a message
      if (linkedAccount) {
        // In a real app, you might show a success message here
        console.log('Successfully linked your Google account!');
      }
      
      navigate('/');
    } catch (err: any) {
      // Special handling for different error types
      if (err.code === 'auth/account-exists-with-different-credential') {
        setError(t('auth.accountExistsWithDifferentCredential') || 'An account already exists with this email');
      } else {
        setError(err.message || 'Failed to register with Google');
      }
    } finally {
      setGoogleLoading(false);
    }
  };

  return (
    <Container maxWidth="xs">
      <Paper elevation={3} sx={{ p: 4, mt: 8 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography component="h1" variant="h5">
            {t('auth.register')}
          </Typography>
          
          {error && (
            <Alert severity="error" sx={{ width: '100%', mt: 2 }}>
              {error}
            </Alert>
          )}
          
          {success && (
            <Alert severity="success" sx={{ width: '100%', mt: 2 }}>
              {t('auth.registrationSuccessful')} <Link component={RouterLink} to="/login">{t('auth.login')}</Link>
            </Alert>
          )}
          
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3, width: '100%' }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="name"
              label={t('auth.fullName')}
              name="name"
              autoComplete="name"
              autoFocus
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label={t('auth.email')}
              name="email"
              autoComplete="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label={t('auth.password')}
              type="password"
              id="password"
              autoComplete="new-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            
            <TextField
              margin="normal"
              required
              fullWidth
              name="confirmPassword"
              label={t('auth.confirmPassword')}
              type="password"
              id="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={loading}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                t('auth.signUp')
              )}
            </Button>
            
            <Divider sx={{ mt: 2, mb: 2 }}>
              <Typography variant="body2" color="text.secondary">
                {t('auth.orContinueWith')}
              </Typography>
            </Divider>
            
            <Button
              fullWidth
              variant="outlined"
              startIcon={<GoogleIcon />}
              onClick={handleGoogleRegister}
              disabled={googleLoading}
              sx={{ mt: 1, mb: 2 }}
            >
              {googleLoading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                t('auth.googleSignIn')
              )}
            </Button>
            
            <Box sx={{ textAlign: 'center', mt: 2 }}>
              <Link component={RouterLink} to="/login" variant="body2">
                {t('auth.alreadyHaveAccount')} {t('auth.login')}
              </Link>
            </Box>
          </Box>
        </Box>
      </Paper>
    </Container>
  );
};

export default Register;