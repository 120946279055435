import React, { useMemo, useState } from 'react';
import { 
  BarChart, 
  Bar, 
  XAxis, 
  YAxis, 
  CartesianGrid, 
  Tooltip, 
  Legend, 
  ResponsiveContainer,
  TooltipProps
} from 'recharts';
import { useExpenses } from '../../contexts/ExpenseContext';
import { 
  Typography, 
  Box, 
  useMediaQuery, 
  Theme, 
  FormControl, 
  InputLabel, 
  Select, 
  MenuItem,
  SelectChangeEvent
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLanguage } from '../../contexts/LanguageContext';

const ExpenseChart: React.FC = () => {
  const { t } = useTranslation();
  const { language } = useLanguage();
  const { 
    expenses, 
    currency, 
    familyMembers, 
    getTotalMonthlyExpenses 
  } = useExpenses();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  
  // State for selected family member (empty string means "All Members")
  const [selectedFamilyMember, setSelectedFamilyMember] = useState<string>('');
  
  // Handle family member change
  const handleFamilyMemberChange = (event: SelectChangeEvent<string>) => {
    setSelectedFamilyMember(event.target.value);
  };
  
  // Filter expenses by selected family member
  const filteredExpenses = useMemo(() => {
    if (!selectedFamilyMember) {
      // If no family member is selected, return all expenses
      return expenses;
    }
    
    // Filter by selected family member
    return expenses.filter(expense => expense.assignedTo === selectedFamilyMember);
  }, [expenses, selectedFamilyMember]);

  // Format currency based on locale and currency code
  const formatCurrency = (value: number): string => {
    // Get the locale based on language, with proper mapping
    const locale = language.code === 'ar' ? 'ar-AE' : 
                  language.code === 'fr' ? 'fr-FR' : 
                  language.code === 'de' ? 'de-DE' : 'en-US';
                  
    return new Intl.NumberFormat(locale, { 
      style: 'currency', 
      currency: currency.code
    }).format(value);
  };

  // Group expenses by month
  const monthlyData = useMemo(() => {
    const months: Record<string, number> = {};
    
    // Initialize all months of the current year
    const currentYear = new Date().getFullYear();
    for (let i = 0; i < 12; i++) {
      // Use locale-aware month name based on selected language
      const locale = language.code === 'ar' ? 'ar-AE' : 
                    language.code === 'fr' ? 'fr-FR' : 
                    language.code === 'de' ? 'de-DE' : 'en-US';
      const monthName = new Date(currentYear, i, 1).toLocaleString(locale, { month: 'short' });
      months[monthName] = 0;
    }
    
    // Add up expenses for each month using the filtered expenses
    filteredExpenses.forEach(expense => {
      if (!expense.isEnabled) return; // Skip disabled expenses
      
      const date = new Date(expense.date);
      const locale = language.code === 'ar' ? 'ar-AE' : 
                    language.code === 'fr' ? 'fr-FR' : 
                    language.code === 'de' ? 'de-DE' : 'en-US';
      const monthName = date.toLocaleString(locale, { month: 'short' });
      
      if (date.getFullYear() === currentYear) {
        if (expense.type === 'regular') {
          // For regular expenses
          if (expense.repeatable) {
            // If repeatable, add to current month and all future months
            const expenseMonth = date.getMonth();
            
            // For each month in the year
            Object.keys(months).forEach((month, index) => {
              // Only add to months starting from the expense date
              if (index >= expenseMonth) {
                months[month] = (months[month] || 0) + expense.amount;
              }
            });
          } else {
            // Non-repeatable expenses go only to their specific month
            months[monthName] = (months[monthName] || 0) + expense.amount;
          }
        } else if (expense.type === 'annual' && expense.isSpreadAcrossMonths) {
          // Annual expenses spread across all months
          const monthlyAmount = expense.amount / 12;
          // Distribute evenly across all months
          Object.keys(months).forEach(month => {
            months[month] = (months[month] || 0) + monthlyAmount;
          });
        }
      }
    });
    
    
    // Convert to array for Recharts
    return Object.entries(months).map(([month, total]) => ({
      month,
      total: parseFloat(total.toFixed(2))
    }));
  }, [filteredExpenses, language.code]);

  // Custom tooltip component with proper currency formatting
  const CustomTooltip = ({ active, payload, label }: TooltipProps<number, string>) => {
    if (active && payload && payload.length) {
      return (
        <Box sx={{ bgcolor: 'background.paper', p: 1, border: '1px solid #ccc', borderRadius: 1 }}>
          <Typography variant="body2" color="text.primary" sx={{ fontWeight: 'bold', mb: 0.5 }}>
            {t('charts.month')}: {label}
          </Typography>
          {payload.map((entry, index) => (
            <Typography 
              key={`item-${index}`} 
              variant="body2" 
              color={entry.color} 
              sx={{ mb: 0.5 }}
            >
              {t('charts.total')}: {formatCurrency(entry.value as number)}
            </Typography>
          ))}
        </Box>
      );
    }
    return null;
  };

  return (
    <Box sx={{ width: '100%' }}>
      {/* Family member filter */}
      <Box sx={{ mb: 2, display: 'flex', justifyContent: 'flex-end' }}>
        <FormControl size="small" sx={{ minWidth: 200 }}>
          <InputLabel id="family-member-select-label">{t('family.family')}</InputLabel>
          <Select
            labelId="family-member-select-label"
            value={selectedFamilyMember}
            onChange={handleFamilyMemberChange}
            label={t('family.family')}
          >
            <MenuItem value="">{t('dashboard.allFamilyMembers', { defaultValue: 'All Family Members' })}</MenuItem>
            {familyMembers.map((member) => (
              <MenuItem key={member.id} value={member.id}>{member.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      
      <Box sx={{ width: '100%', height: isMobile ? 250 : 300 }}>
      {filteredExpenses.length > 0 ? (
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={monthlyData}
            margin={{
              top: 20,
              right: isMobile ? 10 : 30,
              left: isMobile ? 10 : 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis 
              dataKey="month" 
              tick={{ fontSize: isMobile ? 10 : 12 }}
              tickMargin={5}
            />
            <YAxis 
              tick={{ fontSize: isMobile ? 10 : 12 }}
              tickFormatter={(value) => {
                return isMobile 
                  ? `${currency.symbol}${Math.round(value)}` 
                  : formatCurrency(value);
              }}
            />
            <Tooltip content={<CustomTooltip />} />
            <Legend wrapperStyle={{ fontSize: isMobile ? 10 : 12 }} />
            <Bar 
              dataKey="total" 
              name={String(t('charts.monthlySpending', { defaultValue: 'Monthly Spending' }))} 
              fill="#1976d2" 
              radius={[4, 4, 0, 0]}
            />
          </BarChart>
        </ResponsiveContainer>
      ) : (
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center',
          height: '100%',
          p: 2,
          textAlign: 'center'
        }}>
          <Typography variant="body1">
            {t('charts.addExpensesToSeeChart')}
          </Typography>
        </Box>
      )}
      </Box>
    </Box>
  );
};

export default ExpenseChart;