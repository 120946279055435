import React, { useMemo } from 'react';
import { 
  PieChart, 
  Pie, 
  Cell, 
  Tooltip, 
  Legend, 
  ResponsiveContainer,
  TooltipProps
} from 'recharts';
import { useExpenses } from '../../contexts/ExpenseContext';
import { Typography, Box, useMediaQuery, Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';

const COLORS = [
  '#1976d2', '#dc004e', '#ff9800', '#4caf50', 
  '#9c27b0', '#00796b', '#607d8b', '#f44336',
  '#795548', '#673ab7', '#009688', '#3f51b5'
];

const CategoryDistribution: React.FC = () => {
  const { t } = useTranslation();
  const { expenses, currency } = useExpenses();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  // Format currency based on locale and currency code
  const formatCurrency = (value: number): string => {
    // Get the locale based on language, with proper mapping
    const locale = t('locale', { defaultValue: 'en-US' });
    
    return new Intl.NumberFormat(String(locale), { 
      style: 'currency', 
      currency: currency.code
    }).format(value);
  };

  // Group expenses by category
  const categoryData = useMemo(() => {
    const categories: Record<string, number> = {};
    
    // Sum expenses by category
    expenses.forEach(expense => {
      const { category, amount } = expense;
      categories[category] = (categories[category] || 0) + amount;
    });
    
    // Convert to array for Recharts
    const result = Object.entries(categories).map(([name, value]) => ({
      name: t(`categories.${name.toLowerCase()}`, { defaultValue: name }),
      value: parseFloat(value.toFixed(2))
    }));
    
    // Sort by value descending
    return result.sort((a, b) => b.value - a.value);
  }, [expenses, t]);

  // Total expense amount for percentage calculation
  const totalExpenseAmount = useMemo(() => {
    return expenses.reduce((sum, exp) => sum + exp.amount, 0);
  }, [expenses]);

  // Custom tooltip with proper formatting
  const CustomTooltip = ({ active, payload }: TooltipProps<number, string>) => {
    if (active && payload && payload.length) {
      const percentage = (payload[0].value as number / totalExpenseAmount * 100).toFixed(1);
      
      return (
        <Box sx={{ 
          bgcolor: 'background.paper', 
          p: 1, 
          border: '1px solid #ccc', 
          borderRadius: 1,
          minWidth: '150px'
        }}>
          <Typography variant="body2" sx={{ fontWeight: 'bold', mb: 0.5 }}>
            {payload[0].name}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {formatCurrency(payload[0].value as number)}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {percentage}% {t('charts.ofTotal')}
          </Typography>
        </Box>
      );
    }
    return null;
  };

  return (
    <Box sx={{ width: '100%', height: isMobile ? 250 : 300 }}>
      {expenses.length > 0 ? (
        <ResponsiveContainer width="100%" height="100%">
          <PieChart margin={{ top: 5, right: 5, bottom: 5, left: 5 }}>
            <Pie
              data={categoryData}
              cx="50%"
              cy="50%"
              labelLine={false}
              outerRadius={isMobile ? 70 : 80}
              fill="#8884d8"
              dataKey="value"
            >
              {categoryData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <Tooltip content={<CustomTooltip />} />
            <Legend 
              layout={isMobile ? "horizontal" : "vertical"}
              verticalAlign={isMobile ? "bottom" : "middle"}
              align={isMobile ? "center" : "right"}
              wrapperStyle={{ fontSize: isMobile ? 10 : 12 }}
            />
          </PieChart>
        </ResponsiveContainer>
      ) : (
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center',
          height: '100%',
          p: 2,
          textAlign: 'center'
        }}>
          <Typography variant="body1">
            {t('charts.addExpensesToSeeCategoryDistribution')}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default CategoryDistribution;