import React, { useState } from 'react';
import {
  Box,
  Paper,
  Typography,
  Button,
  Divider,
  CircularProgress,
  Alert,
  Avatar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Snackbar
} from '@mui/material';
import {
  Email as EmailIcon,
  Google as GoogleIcon,
  Check as CheckIcon,
  Link as LinkIcon
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../contexts/AuthContext';

const Profile: React.FC = () => {
  const { t } = useTranslation();
  const { currentUser, linkWithGoogle } = useAuth();
  
  const [linking, setLinking] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  
  // Check if user has Google provider
  const hasGoogleProvider = currentUser?.providerData.some(
    provider => provider.providerId === 'google.com'
  );
  
  // Handle Google account linking
  const handleLinkGoogle = async () => {
    if (hasGoogleProvider) {
      setSuccess(true);
      return;
    }
    
    setLinking(true);
    setError('');
    
    try {
      await linkWithGoogle();
      setSuccess(true);
    } catch (err: any) {
      console.error('Error linking with Google:', err);
      
      if (err.code === 'auth/credential-already-in-use' || 
          err.code === 'auth/email-already-in-use') {
        setError(t('auth.googleAccountAlreadyLinked') || 'This Google account is already linked to another account');
      } else {
        setError(err.message || t('auth.errorLinkingAccount') || 'Error linking account');
      }
    } finally {
      setLinking(false);
    }
  };
  
  const handleCloseSnackbar = () => {
    setSuccess(false);
  };
  
  if (!currentUser) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 5 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3, maxWidth: 600, mx: 'auto', mt: 4 }}>
      <Paper elevation={3} sx={{ p: 4 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
          <Avatar 
            sx={{ 
              width: 80, 
              height: 80, 
              mr: 3,
              bgcolor: 'primary.main',
              fontSize: '2rem' 
            }}
          >
            {currentUser.displayName 
              ? currentUser.displayName.charAt(0).toUpperCase() 
              : (currentUser.email?.charAt(0).toUpperCase() || 'U')}
          </Avatar>
          <Box>
            <Typography variant="h5" component="h1">
              {currentUser.displayName || t('auth.userProfile')}
            </Typography>
            <Typography variant="body1" color="text.secondary">
              {currentUser.email}
            </Typography>
          </Box>
        </Box>
        
        <Divider sx={{ my: 3 }} />
        
        <Typography variant="h6" gutterBottom>
          {t('auth.accountConnections')}
        </Typography>
        
        {error && (
          <Alert severity="error" sx={{ mb: 3 }}>
            {error}
          </Alert>
        )}
        
        <List>
          <ListItem>
            <ListItemIcon>
              <EmailIcon />
            </ListItemIcon>
            <ListItemText 
              primary={t('auth.emailPassword')} 
              secondary={currentUser.email} 
            />
            <CheckIcon color="success" />
          </ListItem>
          
          <ListItem>
            <ListItemIcon>
              <GoogleIcon />
            </ListItemIcon>
            <ListItemText 
              primary={t('auth.googleAccount')} 
              secondary={hasGoogleProvider 
                ? t('auth.connected') 
                : t('auth.notConnected')
              } 
            />
            {hasGoogleProvider ? (
              <CheckIcon color="success" />
            ) : (
              <Button
                variant="outlined"
                color="primary"
                startIcon={<LinkIcon />}
                onClick={handleLinkGoogle}
                disabled={linking}
              >
                {linking ? (
                  <CircularProgress size={24} />
                ) : (
                  t('auth.linkAccount')
                )}
              </Button>
            )}
          </ListItem>
        </List>
      </Paper>
      
      <Snackbar
        open={success}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={t('auth.accountSuccessfullyLinked')}
      />
    </Box>
  );
};

export default Profile;