import React, { useMemo } from 'react';
import { 
  BarChart, 
  Bar, 
  XAxis, 
  YAxis, 
  CartesianGrid, 
  Tooltip, 
  Legend, 
  ResponsiveContainer,
  TooltipProps
} from 'recharts';
import { useExpenses } from '../../contexts/ExpenseContext';
import { Typography, Box, useMediaQuery, Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLanguage } from '../../contexts/LanguageContext';

const IncomeVsExpenseChart: React.FC = () => {
  const { t } = useTranslation();
  const { language } = useLanguage();
  const { expenses, getTotalMonthlyIncome, currency } = useExpenses();
  const monthlyIncome = getTotalMonthlyIncome();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  // Custom tooltip formatter using the current locale and currency
  const formatCurrency = (value: number): string => {
    // Get the locale based on language, with proper mapping
    const locale = language.code === 'ar' ? 'ar-AE' : 
                  language.code === 'fr' ? 'fr-FR' : 
                  language.code === 'de' ? 'de-DE' : 'en-US';
                  
    return new Intl.NumberFormat(locale, { 
      style: 'currency', 
      currency: currency.code
    }).format(value);
  };

  // Group expenses by month and compare with income
  const monthlyData = useMemo(() => {
    const months: Record<string, { expenses: number, income: number }> = {};
    
    // Initialize all months of the current year
    const currentYear = new Date().getFullYear();
    for (let i = 0; i < 12; i++) {
      // Use locale-aware month name based on selected language
      const locale = language.code === 'ar' ? 'ar-AE' : 
                     language.code === 'fr' ? 'fr-FR' : 
                     language.code === 'de' ? 'de-DE' : 'en-US';
      const monthName = new Date(currentYear, i, 1).toLocaleString(locale, { month: 'short' });
      months[monthName] = { expenses: 0, income: monthlyIncome };
    }
    
    // Add up expenses for each month
    expenses.forEach(expense => {
      if (!expense.isEnabled) return; // Skip disabled expenses
      
      const date = new Date(expense.date);
      if (date.getFullYear() === currentYear) {
        const locale = language.code === 'ar' ? 'ar-AE' : 
                      language.code === 'fr' ? 'fr-FR' : 
                      language.code === 'de' ? 'de-DE' : 'en-US';
        const monthName = date.toLocaleString(locale, { month: 'short' });
        
        if (expense.type === 'regular') {
          // For regular expenses
          if (expense.repeatable) {
            // If repeatable, add to current month and all future months
            const expenseMonth = date.getMonth();
            
            // Get array of month names in order
            const monthNames = Object.keys(months);
            
            // For each month in the year
            monthNames.forEach((month, index) => {
              // Only add to months starting from the expense date
              if (index >= expenseMonth) {
                months[month].expenses = (months[month].expenses || 0) + expense.amount;
              }
            });
          } else {
            // Non-repeatable expenses go only to their specific month
            months[monthName].expenses = (months[monthName].expenses || 0) + expense.amount;
          }
        } else if (expense.type === 'annual' && expense.isSpreadAcrossMonths) {
          // Annual expenses spread across all months
          const monthlyAmount = expense.amount / 12;
          // Distribute evenly across all months
          Object.keys(months).forEach(month => {
            months[month].expenses = (months[month].expenses || 0) + monthlyAmount;
          });
        }
      }
    });
    
    // Convert to array for Recharts
    return Object.entries(months).map(([month, data]) => ({
      month,
      expenses: parseFloat(data.expenses.toFixed(2)),
      income: parseFloat(data.income.toFixed(2))
    }));
  }, [expenses, monthlyIncome, language.code]);

  // Custom tooltip component with proper currency formatting
  const CustomTooltip = ({ active, payload, label }: TooltipProps<number, string>) => {
    if (active && payload && payload.length) {
      return (
        <Box sx={{ bgcolor: 'background.paper', p: 1, border: '1px solid #ccc', borderRadius: 1 }}>
          <Typography variant="body2" color="text.primary" sx={{ fontWeight: 'bold', mb: 0.5 }}>
            {t('charts.month')}: {label}
          </Typography>
          {payload.map((entry, index) => (
            <Typography 
              key={`item-${index}`} 
              variant="body2" 
              color={entry.color} 
              sx={{ mb: 0.5 }}
            >
              {entry.name}: {formatCurrency(entry.value as number)}
            </Typography>
          ))}
        </Box>
      );
    }
    return null;
  };

  return (
    <Box sx={{ width: '100%', height: isMobile ? 250 : 300 }}>
      {monthlyIncome > 0 ? (
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={monthlyData}
            margin={{
              top: 20,
              right: isMobile ? 10 : 30,
              left: isMobile ? 10 : 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis 
              dataKey="month" 
              tick={{ fontSize: isMobile ? 10 : 12 }}
              tickMargin={5}
            />
            <YAxis 
              tick={{ fontSize: isMobile ? 10 : 12 }}
              tickFormatter={(value) => {
                return isMobile 
                  ? `${currency.symbol}${Math.round(value)}` 
                  : formatCurrency(value);
              }}
            />
            <Tooltip content={<CustomTooltip />} />
            <Legend 
              wrapperStyle={{ fontSize: isMobile ? 10 : 12 }} 
            />
            <Bar 
              dataKey="income" 
              name={String(t('charts.monthlyIncome', { defaultValue: 'Monthly Income' }))} 
              fill="#4caf50" 
              radius={[4, 4, 0, 0]}
            />
            <Bar 
              dataKey="expenses" 
              name={String(t('charts.monthlyExpenses', { defaultValue: 'Monthly Expenses' }))} 
              fill="#f44336" 
              radius={[4, 4, 0, 0]}
            />
          </BarChart>
        </ResponsiveContainer>
      ) : (
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center',
          height: '100%',
          p: 2,
          textAlign: 'center'
        }}>
          <Typography variant="body1">
            {t('charts.setIncomeToSeeChart')}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default IncomeVsExpenseChart;