import React, { useState } from 'react';
import {
  Paper,
  Typography,
  Box,
  Button,
  TextField,
  IconButton,
  Chip,
  Grid,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Alert
} from '@mui/material';
import { Add, Edit, Delete } from '@mui/icons-material';
import { useExpenses } from '../../contexts/ExpenseContext';

// Default categories that cannot be edited or deleted
const defaultCategories = [
  'Food',
  'Transportation',
  'Entertainment',
  'Housing',
  'Utilities',
  'Healthcare',
  'Shopping',
  'Health Insurance',
  'Car Insurance',
  'Home Insurance',
  'Life Insurance',
  'Travel Insurance',
  'Other'
];

const CategoryManager: React.FC = () => {
  const { categories, addCategory, updateCategory, deleteCategory } = useExpenses();
  
  const [open, setOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [currentCategory, setCurrentCategory] = useState('');
  const [newCategory, setNewCategory] = useState('');
  const [error, setError] = useState('');

  const handleClickOpen = (isEdit: boolean = false, category?: string) => {
    setEditMode(isEdit);
    setError('');
    if (isEdit && category) {
      setCurrentCategory(category);
      setNewCategory(category);
    } else {
      setCurrentCategory('');
      setNewCategory('');
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    // Validation
    if (!newCategory.trim()) {
      setError('Category name cannot be empty');
      return;
    }
    
    if (categories.includes(newCategory) && (!editMode || newCategory !== currentCategory)) {
      setError('Category already exists');
      return;
    }
    
    if (editMode) {
      updateCategory(currentCategory, newCategory);
    } else {
      addCategory(newCategory);
    }
    
    handleClose();
  };

  const handleDelete = (category: string) => {
    if (!defaultCategories.includes(category)) {
      deleteCategory(category);
    }
  };

  const isDefaultCategory = (category: string) => {
    return defaultCategories.includes(category);
  };

  return (
    <Paper sx={{ p: 3, mb: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h6">Expense Categories</Typography>
        <Button
          variant="contained"
          startIcon={<Add />}
          onClick={() => handleClickOpen(false)}
        >
          Add Category
        </Button>
      </Box>

      <Divider sx={{ mb: 3 }} />

      {/* Categories Display */}
      <Grid container spacing={2}>
        <Grid item xs={12} mb={2}>
          <Typography variant="subtitle1" gutterBottom>
            Default Categories
          </Typography>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
            {categories
              .filter(cat => isDefaultCategory(cat))
              .map(category => (
                <Chip
                  key={category}
                  label={category}
                  color="primary"
                  variant="outlined"
                />
              ))}
          </Box>
        </Grid>
        
        <Grid item xs={12}>
          <Typography variant="subtitle1" gutterBottom>
            Custom Categories
          </Typography>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
            {categories
              .filter(cat => !isDefaultCategory(cat))
              .map(category => (
                <Chip
                  key={category}
                  label={category}
                  onDelete={() => handleDelete(category)}
                  deleteIcon={<Delete />}
                  onClick={() => handleClickOpen(true, category)}
                  sx={{ cursor: 'pointer' }}
                />
              ))}
            {categories.filter(cat => !isDefaultCategory(cat)).length === 0 && (
              <Typography variant="body2" color="text.secondary">
                No custom categories yet. Add your first custom category!
              </Typography>
            )}
          </Box>
        </Grid>
      </Grid>

      {/* Add/Edit Category Dialog */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {editMode ? 'Edit Category' : 'Add Category'}
        </DialogTitle>
        <DialogContent>
          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}
          <TextField
            autoFocus
            margin="dense"
            id="category"
            label="Category Name"
            type="text"
            fullWidth
            variant="outlined"
            value={newCategory}
            onChange={(e) => setNewCategory(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave} variant="contained">
            {editMode ? 'Update' : 'Add'}
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default CategoryManager;