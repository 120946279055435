import React, { useState } from 'react';
import { 
  Grid, 
  Paper, 
  Typography, 
  Tab, 
  Tabs, 
  Box, 
  Divider,
  useMediaQuery,
  Theme 
} from '@mui/material';
import { 
  Add, 
  Settings as SettingsIcon, 
  Person as PersonIcon, 
  CategoryRounded,
  CalendarMonth,
  MonetizationOn
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import ExpenseForm from '../expenses/ExpenseForm';
import ExpenseList from '../expenses/ExpenseList';
import ExpenseChart from '../charts/ExpenseChart';
import CategoryDistribution from '../charts/CategoryDistribution';
import IncomeVsExpenseChart from '../charts/IncomeVsExpenseChart';
import BudgetManager from './BudgetManager';
import FamilyManager from '../family/FamilyManager';
import CategoryManager from '../categories/CategoryManager';
import AnnualExpenseManager from '../expenses/AnnualExpenseManager';
import IncomeManager from '../income/IncomeManager';
import { useExpenses } from '../../contexts/ExpenseContext';
import { useLanguage } from '../../contexts/LanguageContext';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`dashboard-tabpanel-${index}`}
      aria-labelledby={`dashboard-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

const Dashboard: React.FC = () => {
  const [tabValue, setTabValue] = useState(0);
  const { t } = useTranslation();
  const { language } = useLanguage();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  
  const { 
    expenses, 
    incomeSources,
    getCurrentMonthExpenses, 
    getMonthlyBalance, 
    getBudgetStatus,
    currency,
    getTotalMonthlyIncome,
    getAnnualExpenses,
    getMonthlyEquivalentOfAnnualExpenses,
    getTotalMonthlyExpenses,
    getRegularMonthlyExpenses,
    getProratedAnnualExpenses,
    getTotalAnnualIncome,
    getTotalAnnualExpenses,
    getAnnualBalance,
    familyMembers
  } = useExpenses();

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  // Get regular monthly expenses for all family members
  const regularMonthExpenses = getRegularMonthlyExpenses();
  
  // Get prorated annual expenses for all family members
  const monthlyEquivalentOfAnnual = getProratedAnnualExpenses();
  
  // Get total monthly expenses (regular + annual)
  const totalMonthExpenses = getTotalMonthlyExpenses();
  
  // Get annual expenses for statistics display
  const annualExpenses = getAnnualExpenses();
  
  // Debug information about expenses
  console.log("Dashboard - All Expenses:", expenses.length);
  console.log("Dashboard - Enabled Expenses:", expenses.filter(e => e.isEnabled).length);
  console.log("Dashboard - Regular Expenses:", expenses.filter(e => e.type === 'regular').length);
  console.log("Dashboard - Annual Expenses:", expenses.filter(e => e.type === 'annual').length);
  
  // Debug current month's expenses
  const now = new Date();
  const currentMonth = now.getMonth();
  const currentYear = now.getFullYear();
  
  const currentMonthRegularExpenses = expenses.filter(e => 
    e.isEnabled && 
    e.type === 'regular' && 
    new Date(e.date).getMonth() === currentMonth &&
    new Date(e.date).getFullYear() === currentYear
  );
  
  console.log("Dashboard - Current Month Regular Expenses:", currentMonthRegularExpenses.length);
  console.log("Dashboard - Current Month Regular Details:", 
    currentMonthRegularExpenses.map(e => ({
      amount: e.amount,
      description: e.description,
      enabled: e.isEnabled,
      date: new Date(e.date).toISOString().slice(0, 10)
    }))
  );
  
  // Get monthly income
  const monthlyIncome = getTotalMonthlyIncome();
  
  // Get monthly balance
  const monthlyBalance = getMonthlyBalance();
  
  // Get budget status
  const budgetStatus = getBudgetStatus();
  
  // Format currency based on locale and currency code
  const formatCurrency = (amount: number): string => {
    // Get the locale based on language
    const locale = language.code === 'ar' ? 'ar-AE' : 
                  language.code === 'fr' ? 'fr-FR' : 
                  language.code === 'de' ? 'de-DE' : 'en-US';
    
    return new Intl.NumberFormat(locale, { 
      style: 'currency', 
      currency: currency.code
    }).format(amount);
  };
  
  // Status color
  const getStatusColor = (status: 'good' | 'warning' | 'danger') => {
    switch (status) {
      case 'good': return 'success.main';
      case 'warning': return 'warning.main';
      case 'danger': return 'error.main';
      default: return 'primary.main';
    }
  };
  
  // Get status text based on budget status
  const getStatusText = (status: 'good' | 'warning' | 'danger') => {
    switch (status) {
      case 'good': return t('dashboard.onBudget');
      case 'warning': return t('dashboard.budgetCaution');
      case 'danger': return t('dashboard.overBudget');
      default: return '';
    }
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        {t('dashboard.financeTracker')}
      </Typography>
      
      {/* Monthly Summary Cards - Moved to the top */}
      <Grid container spacing={3} sx={{ mb: 3 }}>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Monthly Overview
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', height: isMobile ? 'auto' : 140, mb: isMobile ? 2 : 0 }}>
            <Typography variant="h6" color="primary">{t('dashboard.totalMonthlyIncome')}</Typography>
            <Typography variant="h4">
              {formatCurrency(monthlyIncome)}
            </Typography>
            <Box sx={{ 
              display: 'flex', 
              flexDirection: isMobile ? 'column' : 'row',
              justifyContent: 'space-between', 
              alignItems: isMobile ? 'flex-start' : 'center'
            }}>
              <Typography variant="caption" color="text.secondary">
                {t('currencies.' + currency.code)} • {t('dashboard.incomeSources', { count: incomeSources.length })}
              </Typography>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', height: isMobile ? 'auto' : 140, mb: isMobile ? 2 : 0 }}>
            <Typography variant="h6" color="primary">{t('dashboard.monthlyExpenses')}</Typography>
            <Typography variant="h4">
              {formatCurrency(totalMonthExpenses)}
            </Typography>
            <Box sx={{ 
              display: 'flex', 
              flexDirection: isMobile ? 'column' : 'row',
              justifyContent: 'space-between', 
              alignItems: isMobile ? 'flex-start' : 'center',
              mt: isMobile ? 1 : 0
            }}>
              <Typography variant="caption" color="text.secondary" sx={{ mb: isMobile ? 1 : 0 }}>
                {t('currencies.' + currency.code)}
              </Typography>
              <Typography variant="caption" color="text.secondary">
                {t('expenses.regular')}: {formatCurrency(regularMonthExpenses > 0 ? regularMonthExpenses : 0)} • 
                {t('expenses.annual')}: {formatCurrency(monthlyEquivalentOfAnnual > 0 ? monthlyEquivalentOfAnnual : 0)}
              </Typography>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', height: isMobile ? 'auto' : 140 }}>
            <Typography variant="h6" color="primary">{t('dashboard.monthlyBalance')}</Typography>
            <Typography variant="h4" color={monthlyBalance >= 0 ? 'success.main' : 'error.main'}>
              {formatCurrency(monthlyBalance)}
            </Typography>
            <Box sx={{ 
              display: 'flex', 
              flexDirection: isMobile ? 'column' : 'row',
              justifyContent: 'space-between', 
              alignItems: isMobile ? 'flex-start' : 'center',
              mt: isMobile ? 1 : 0
            }}>
              <Typography variant="caption" color="text.secondary" sx={{ mb: isMobile ? 1 : 0 }}>
                {t('currencies.' + currency.code)}
              </Typography>
              <Typography variant="caption" color={getStatusColor(budgetStatus)}>
                {getStatusText(budgetStatus)}
              </Typography>
            </Box>
          </Paper>
        </Grid>
      </Grid>
      
      {/* Annual Summary Cards */}
      <Grid container spacing={3} sx={{ mb: 4 }}>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Annual Overview
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', height: isMobile ? 'auto' : 140, mb: isMobile ? 2 : 0 }}>
            <Typography variant="h6" color="primary">Annual Income</Typography>
            <Typography variant="h4">
              {formatCurrency(getTotalAnnualIncome())}
            </Typography>
            <Box sx={{ 
              display: 'flex', 
              flexDirection: isMobile ? 'column' : 'row',
              justifyContent: 'space-between', 
              alignItems: isMobile ? 'flex-start' : 'center'
            }}>
              <Typography variant="caption" color="text.secondary">
                {t('currencies.' + currency.code)}
              </Typography>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', height: isMobile ? 'auto' : 140, mb: isMobile ? 2 : 0 }}>
            <Typography variant="h6" color="primary">Annual Expenses</Typography>
            <Typography variant="h4">
              {formatCurrency(getTotalAnnualExpenses())}
            </Typography>
            <Box sx={{ 
              display: 'flex', 
              flexDirection: isMobile ? 'column' : 'row',
              justifyContent: 'space-between', 
              alignItems: isMobile ? 'flex-start' : 'center',
              mt: isMobile ? 1 : 0
            }}>
              <Typography variant="caption" color="text.secondary">
                {t('currencies.' + currency.code)}
              </Typography>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', height: isMobile ? 'auto' : 140 }}>
            <Typography variant="h6" color="primary">Annual Balance</Typography>
            <Typography variant="h4" color={getAnnualBalance() >= 0 ? 'success.main' : 'error.main'}>
              {formatCurrency(getAnnualBalance())}
            </Typography>
            <Box sx={{ 
              display: 'flex', 
              flexDirection: isMobile ? 'column' : 'row',
              justifyContent: 'space-between', 
              alignItems: isMobile ? 'flex-start' : 'center',
              mt: isMobile ? 1 : 0
            }}>
              <Typography variant="caption" color="text.secondary">
                {t('currencies.' + currency.code)}
              </Typography>
              <Typography variant="caption" color={getAnnualBalance() >= 0 ? 'success.main' : 'error.main'}>
                {getAnnualBalance() >= 0 ? 'Savings' : 'Deficit'}
              </Typography>
            </Box>
          </Paper>
        </Grid>
      </Grid>
      
      {/* Charts Section - Now placed after budget summaries */}
      <Grid container spacing={3} sx={{ mb: 4 }}>
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6">{t('charts.incomeVsExpense')}</Typography>
            <IncomeVsExpenseChart />
          </Paper>
        </Grid>
                
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6">{t('charts.monthlyExpenses')}</Typography>
            <ExpenseChart />
          </Paper>
        </Grid>
                
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6">{t('charts.expensesByCategory')}</Typography>
            <CategoryDistribution />
          </Paper>
        </Grid>
      </Grid>
      
      {/* Budget Manager Section */}
      <BudgetManager />
      
      <Grid container spacing={3}>

        {/* Tabs - Mobile Friendly */}
        <Grid item xs={12}>
          <Paper sx={{ width: '100%' }}>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons={true}
              allowScrollButtonsMobile
              aria-label="dashboard tabs"
              sx={{ 
                maxWidth: '100%',
                '& .MuiTab-root': {
                  minWidth: isMobile ? 'auto' : 160,
                  fontSize: isMobile ? '0.75rem' : 'inherit'
                }
              }}
            >
              <Tab icon={<Add />} iconPosition="start" label={t('expenses.addExpense')} />
              <Tab label={t('expenses.expenses')} />
              <Tab icon={<CalendarMonth />} iconPosition="start" label={t('expenses.annual')} />
              <Tab icon={<MonetizationOn />} iconPosition="start" label={t('income.income')} />
              <Tab icon={<PersonIcon />} iconPosition="start" label={t('family.family')} />
              <Tab icon={<CategoryRounded />} iconPosition="start" label={t('categories.categories')} />
              <Tab icon={<SettingsIcon />} iconPosition="start" label={t('header.settings')} />
            </Tabs>
            
            {/* Add Expense Panel */}
            <TabPanel value={tabValue} index={0}>
              <ExpenseForm />
            </TabPanel>
            
            {/* Expense List Panel */}
            <TabPanel value={tabValue} index={1}>
              <ExpenseList />
            </TabPanel>
            
            {/* Annual Expenses Panel */}
            <TabPanel value={tabValue} index={2}>
              <AnnualExpenseManager />
            </TabPanel>
            
            {/* Income Panel */}
            <TabPanel value={tabValue} index={3}>
              <IncomeManager />
            </TabPanel>
            
            {/* Family Members Panel */}
            <TabPanel value={tabValue} index={4}>
              <FamilyManager />
            </TabPanel>
            
            {/* Categories Panel */}
            <TabPanel value={tabValue} index={5}>
              <CategoryManager />
            </TabPanel>
            
            {/* Settings Panel */}
            <TabPanel value={tabValue} index={6}>
              <Paper sx={{ p: isMobile ? 2 : 3 }}>
                <Typography variant="h6" gutterBottom>
                  {t('header.settings')}
                </Typography>
                
                <Divider sx={{ mb: 3 }} />
                
                <Box sx={{ mb: 3 }}>
                  <Typography variant="subtitle1" gutterBottom>
                    {t('dashboard.appStatistics')}
                  </Typography>
                  <Grid container spacing={isMobile ? 1 : 2}>
                    <Grid item xs={6} md={3}>
                      <Paper sx={{ p: isMobile ? 1 : 2, textAlign: 'center' }}>
                        <Typography variant="subtitle2" color="text.secondary">
                          {t('dashboard.totalExpenses')}
                        </Typography>
                        <Typography variant="h5">{expenses.length}</Typography>
                      </Paper>
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <Paper sx={{ p: isMobile ? 1 : 2, textAlign: 'center' }}>
                        <Typography variant="subtitle2" color="text.secondary">
                          {t('dashboard.annualExpenses')}
                        </Typography>
                        <Typography variant="h5">{annualExpenses.length}</Typography>
                      </Paper>
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <Paper sx={{ p: isMobile ? 1 : 2, textAlign: 'center' }}>
                        <Typography variant="subtitle2" color="text.secondary">
                          {t('income.incomeSources')}
                        </Typography>
                        <Typography variant="h5">{incomeSources.length}</Typography>
                      </Paper>
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <Paper sx={{ p: isMobile ? 1 : 2, textAlign: 'center' }}>
                        <Typography variant="subtitle2" color="text.secondary">
                          {t('family.familyMembers')}
                        </Typography>
                        <Typography variant="h5">{familyMembers.length}</Typography>
                      </Paper>
                    </Grid>
                  </Grid>
                </Box>
                
              </Paper>
            </TabPanel>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;